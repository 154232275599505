import { Grid, TextInput } from "@mantine/core";
import CountriesList from "components/essentials/CountriesList";
import { useFormContext } from "react-hook-form";
import ImageUpload from "components/core/ImageUpload/ImageUpload";

interface IFSpeakerDetailsForm {
  currentPhoto: string;
}

const SpeakerPersonalDetailsForm = ({ currentPhoto }: IFSpeakerDetailsForm) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid justify="space-around" p={10}>
      <Grid.Col md={6} lg={2}>
        <ImageUpload
          placeholderText="Select Profile photo"
          currentPhoto={currentPhoto}
          name="profile-photo"
        />
      </Grid.Col>
      <Grid.Col md={12} lg={8}>
        <Grid>
          <Grid.Col md={12} lg={3}>
            <TextInput
              withAsterisk
              {...register("honorific")}
              placeholder="Honorific"
              label="Honorific"
              error={errors.honorific?.message as string}
            />
          </Grid.Col>
          <Grid.Col md={12} lg={3}>
            <TextInput
              withAsterisk
              {...register("name")}
              placeholder="Full name"
              label="Full name"
              error={errors.name?.message as string}
            />
          </Grid.Col>
          <Grid.Col md={12} lg={3}>
            <TextInput
              withAsterisk
              placeholder="Middle name"
              {...register("middle_name")}
              label="Middle name"
              error={errors.middle_name?.message as string}
            />
          </Grid.Col>
          <Grid.Col md={12} lg={3}>
            <TextInput
              withAsterisk
              placeholder="Phone"
              {...register("phone")}
              label="Phone"
              error={errors.phone?.message as string}
            />
          </Grid.Col>
          <Grid.Col md={12} lg={3}>
            <TextInput
              withAsterisk
              placeholder="Job Title"
              {...register("job_title")}
              label="Job Title"
              error={errors.job_title?.message as string}
            />
          </Grid.Col>
          <Grid.Col md={12} lg={3}>
            <TextInput
              withAsterisk
              placeholder="City"
              {...register("city")}
              label="City"
              error={errors.city?.message as string}
              mb={10}
            />
          </Grid.Col>
          <Grid.Col md={12} lg={3}>
            <CountriesList />
          </Grid.Col>
          <Grid.Col md={12} lg={3}>
            <TextInput
              withAsterisk
              placeholder="Email"
              {...register("email")}
              label="Email"
              error={errors.email?.message as string}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};

export default SpeakerPersonalDetailsForm;
