import { createContext, PropsWithChildren, useState } from "react";

interface IFHowToGet {
  action_text: string | undefined;
  action_url: string | undefined;
  description: string | undefined;
  transportation_type: string | undefined;
  time_to_destination: string | undefined;
}
interface IFHotelContext {
  rating: number;
  howToGetThere: Array<IFHowToGet>;
  alternateHotels: Array<number>;
  mainHotel: Array<number>;
  debouncedSearchHotel: string;
  debouncedSearchHotels: string;
  handleSetRating: (index: number) => void;
  deleteHowToGetThere: (index: number) => void;
  handleHowToGet: (updated: IFHowToGet[]) => void;
  addHowToGetThere: (newData: IFHowToGet) => void;
  handleAlternateHotels: (hotels: any) => void;
  handleMainHotel: (hotel: any) => void;
  handleDebouncedSearchHotel: (hotels: string) => void;
  handleDebouncedSearchHotels: (value: string) => void;
  resetContextState: () => void;
}

const HotelContext = createContext({} as IFHotelContext);
export const HotelContextProvider = ({ children }: PropsWithChildren) => {
  const [rating, setRating] = useState<number>(0);
  const [howToGetThere, setHowToGetArray] = useState<IFHowToGet[]>([]);
  const [alternateHotels, setAlternateHotels] = useState([]);
  const [mainHotel, setMainHotel] = useState<any>([]);
  const [debouncedSearchHotel, setDebouncedSearchHotel] = useState("");
  const [debouncedSearchHotels, setDebouncedSearchHotels] = useState("");

  const handleSetRating = (rating: number) => {
    setRating(rating);
  };

  const handleHowToGet = (updated: IFHowToGet[]) => {
    setHowToGetArray(updated);
  };
  const addHowToGetThere = (newData: IFHowToGet) => {
    setHowToGetArray([...howToGetThere, newData]);
  };

  const deleteHowToGetThere = (fileIndex: number) =>
    setHowToGetArray(
      howToGetThere.filter(
        (item: IFHowToGet) => item !== howToGetThere[fileIndex]
      )
    );

  const handleAlternateHotels = (hotels: any) => {
    setAlternateHotels(hotels);
  };
  const handleMainHotel = (hotel: any) => {
    setMainHotel(hotel);
  };

  const handleDebouncedSearchHotel = (value: any) => {
    setDebouncedSearchHotel(value);
  };
  const handleDebouncedSearchHotels = (value: any) => {
    setDebouncedSearchHotels(value);
  };

  const resetContextState = () => {
    setAlternateHotels([]);
    setMainHotel([]);
    setDebouncedSearchHotels("");
    setDebouncedSearchHotel("");
  };

  return (
    <HotelContext.Provider
      value={{
        rating,
        mainHotel,
        howToGetThere,
        alternateHotels,
        debouncedSearchHotel,
        debouncedSearchHotels,
        handleSetRating,
        handleHowToGet,
        deleteHowToGetThere,
        addHowToGetThere,
        handleAlternateHotels,
        handleMainHotel,
        handleDebouncedSearchHotel,
        handleDebouncedSearchHotels,
        resetContextState,
      }}
    >
      {children}
    </HotelContext.Provider>
  );
};

export default HotelContext;
