import { useContext } from "react";
import HotelContext from "context/HotelContext";
import CoreMultiselect from "components/core/CoreMultiselect";
import { useFilterVenue } from "services/enteties/hotels";

const MainHotelSearch = () => {
  const {
    mainHotel,
    debouncedSearchHotel,
    handleDebouncedSearchHotel,
    handleMainHotel,
  } = useContext(HotelContext);

  const { data: mainHotelData, isFetching: fetchingMainHotel } =
    useFilterVenue(debouncedSearchHotel);

  return (
    <CoreMultiselect
      maxLimit={1}
      placeholder="Primary Hotel"
      label="Primary Hotel"
      handleDebounced={handleDebouncedSearchHotel}
      data={mainHotelData}
      isFetching={fetchingMainHotel}
      setSelected={handleMainHotel}
      selected={mainHotel}
    />
  );
};

export default MainHotelSearch;
