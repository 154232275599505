import http from "services/http";
import { useQuery } from "@tanstack/react-query";
import { SiteSettings } from "types/site-settings";

export const useGetSiteSettings = () => {
	return useQuery(["site-settings"], () => http.get(`/api/site-settings`), {
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		select(data):SiteSettings {
			return data.data;
		},
	});
};
