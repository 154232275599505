import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TicketData } from "types/ticket";

export const useUpdateTicket = (id: string | undefined) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data: TicketData) =>
			http.patch(`/api/tickets/${id}`, { ...data }),
		onSuccess: () => {
			queryClient.invalidateQueries(["tickets"]);
		},
	});
};
