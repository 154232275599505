import { useState, useContext, useEffect } from "react";
import ImageUploadContext from "context/FileUploadContext";
import { useAllHotelsPagination } from "services/enteties/hotels";
import { Flex, Grid, Button, Text, Divider, Switch } from "@mantine/core";
import PageWrapper from "components/core/PageWrapper";
import { IconCheck, IconPlus, IconX } from "@tabler/icons";
import CoreTable from "components/core/CoreTable";
import { useNavigate } from "react-router-dom";
import { useDeleteHotel } from "services/enteties/hotels";

import CoreModal from "components/core/CoreModal";
import { useForm, FormProvider } from "react-hook-form";
import MultiplePhotosUpload from "components/core/ImageUpload/MultiplePhotosUpload";
import HotelDetailsForm from "components/forms/hotels/HotelDetailsForm";
import { useCreateHotel } from "services/enteties/hotels";
import { showNotification } from "@mantine/notifications";
import HotelContext from "context/HotelContext";
import {
	useGetSiteSettings,
	useUpdateSiteSettings,
} from "services/enteties/site-settings";
import FileUpload from "components/core/ImageUpload/FileUpload";
const Hotels = () => {
	const [page, setPage] = useState(1);
	const handlePagination = (page: number) => setPage(page);
	const { data, isLoading, isFetching } = useAllHotelsPagination(page);

	// Book now Button/Switch
	const { data: siteSettings, isLoading: siteSettingsLoading } =
		useGetSiteSettings();

	const [showBanner, setShowBanner] = useState(
		siteSettings?.show_banner === "true"
	);

	useEffect(() => {
		setShowBanner(siteSettings?.show_banner === "true");
	}, [siteSettings?.show_banner]);

	const { mutate: updateSiteSettings, isLoading: siteSettingsMutating } =
		useUpdateSiteSettings();

	const handleToggleBookNow = () => {
		const newShowBanner = !showBanner;
		updateSiteSettings([{ show_banner: String(newShowBanner) }], {
			onSuccess: () => {
				setShowBanner(newShowBanner);
			},
			onError: (error: any) => {
				showNotification({
					title: "Error",
					icon: <IconCheck />,
					message: error.response.data.message,
					color: "red",
				});
			},
		});
	};

	const navigate = useNavigate();
	const { mutate: deleteHotel } = useDeleteHotel();
	const [opened, setOpened] = useState(false);

	const [deleteId, setDeleteId] = useState<any>("");

	const { imageState, imagesState, cleanupContextState, fileState } =
		useContext(ImageUploadContext);

	const { rating } = useContext(HotelContext);
	const { mutate } = useCreateHotel();

	const headerColumns = [
		{ id: 1, title: "id" },
		{ id: 2, title: "Name" },
		{ id: 4, title: "Type" },
		{ id: 3, title: "Address" },
		{ id: 5, title: "Country" },
		{ id: 6, title: "Available" },
		{ id: 7, title: "Created" },
		{ id: 8, title: "" },
	];
	const rows = data?.data?.map((info: any, key: number) => (
		<tr key={key}>
			<td>{info.id}</td>
			<td>{info.name}</td>
			<td>{info.type}</td>
			<td>{info.address}</td>
			<td>{info.country}</td>
			<td>
				{info.is_available ? (
					<IconCheck size={17} />
				) : (
					<IconX size={17} />
				)}
			</td>
			<td>{new Date(info.created_at).toLocaleDateString()}</td>
			<td>
				<Button
					onClick={() => navigate(`/hotels/${info.id}`)}
					variant="subtle"
					size="xs"
				>
					Edit
				</Button>
				<Button
					onClick={() => setDeleteId(info.id)}
					variant="subtle"
					color="red"
					size="xs"
				>
					Delete
				</Button>
			</td>
		</tr>
	));

	const methods = useForm({
		// resolver: yupResolver(yup.object().shape(hotelValidation())),
	});

	const { handleSubmit, reset } = methods;

	const onSubmit = (data: any) => {
		mutate(
			{
				...data,
				rating,
				...(imageState.length && {
					hero_image:
						imageState?.find((el) => el.name === "hero_image")
							?.file?.[0] ?? null,
				}),
				...(imageState.length && {
					banner_image:
						imageState?.find((el) => el.name === "banner_image")
							?.file?.[0] ?? null,
				}),
				...(imagesState.length && { images: imagesState }),
				...(fileState.length && { floor_plan: fileState[0] }),
			},
			{
				onSuccess: () => {
					setOpened(false);
					showNotification({
						title: "Success",
						icon: <IconCheck />,
						message: "Successfully updated",
					});
					cleanupContextState();
					reset();
				},
				onError: (error: any) => {
					showNotification({
						title: "Error",
						icon: <IconCheck />,
						message: error.response.data.message,
						color: "red",
					});
				},
			}
		);
	};

	return (
		<PageWrapper
			title="Hotels"
			action={
				<Flex align="center" gap="xl">
					<Flex gap={"xs"} align="center">
						<Text fz="xs">Show "Book now"</Text>
						<Switch
							disabled={
								siteSettingsLoading || siteSettingsMutating
							}
							label=""
							labelPosition="left"
							onLabel="ON"
							offLabel="OFF"
							size="md"
							onChange={handleToggleBookNow}
							checked={showBanner}
						/>
					</Flex>
					<Button
						leftIcon={<IconPlus size={20} />}
						onClick={() => setOpened(true)}
					>
						Add New Hotel
					</Button>
				</Flex>
			}
		>
			<CoreModal
				opened={opened}
				onClose={() => setOpened(false)}
				title={"Create New Hotel"}
				size={1200}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormProvider {...methods}>
						<HotelDetailsForm createNew={true} />
						<Grid justify="space-evenly" mt={50}>
							<Grid.Col md={12} lg={4}>
								<Text fw={500}>Hotel photos</Text>
								<MultiplePhotosUpload
									placeholderText="Click to select hotel photos"
									queryKey={"hotel"}
								/>
							</Grid.Col>
							<Divider size={1} orientation="vertical" />
							<Grid.Col md={12} lg={4}>
								<Text fw={400} mb={10}>
									Hotel floor plan
								</Text>
								<FileUpload
									maxFiles={1}
									placeholderText="Click here to select"
								/>
							</Grid.Col>
						</Grid>
					</FormProvider>
					<Button mx="auto" type="submit" mt={20}>
						Create Hotel
					</Button>
				</form>
			</CoreModal>

			<CoreModal
				opened={!!deleteId}
				onClose={() => setDeleteId("")}
				title={"Delete Hotel"}
			>
				<Text ta="center" fw={500} my={20}>
					Are you sure you want to delete this hotel?
				</Text>
				<Flex justify="space-evenly">
					<Button onClick={() => setDeleteId("")}>Cancel</Button>
					<Button
						onClick={() => {
							deleteHotel(deleteId);
							setDeleteId("");
						}}
					>
						Confirm
					</Button>
				</Flex>
			</CoreModal>
			<CoreTable
				rows={rows}
				page={page}
				headerColumns={headerColumns}
				perPage={data?.meta?.per_page}
				totalResults={data?.meta?.total}
				from={data?.meta?.from}
				to={data?.meta?.to}
				lastPage={data?.meta?.last_page}
				loading={isLoading}
				fetching={isFetching}
				handlePagination={handlePagination}
			/>
		</PageWrapper>
	);
};

export default Hotels;
