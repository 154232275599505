import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isArray } from "lodash";

export const useUpdateSiteSettings = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data: any) => {
			if (!isArray(data)) data = new Array(data);
			data = data.reduce((acc: any, curr: any) => {
				const { key, value } = curr;
				acc[key] = value;
				return acc;
			});
			return http.post(`/api/site-settings/update`, {
				_method: "PATCH",
				values: data,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["site-settings"]);
		},
	});
};
