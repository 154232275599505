import {
	Box,
	Table,
	Card,
	Text,
	Pagination,
	LoadingOverlay,
	Divider,
	createStyles,
	ScrollArea,
} from "@mantine/core";
import { IFCoreTable, IFHeaderColumn } from "types/table";

const useStyles = createStyles((theme) => ({
	table: {
		[theme.fn.smallerThan("sm")]: {
			overflowX: "scroll",
			width: 600,
		},
	},
	pagination: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
}));
const CoreTable = ({
	loading,
	fetching,
	headerColumns,
	rows,
	page,
	perPage,
	totalResults,
	to,
	from,
	lastPage,
	handlePagination,
}: IFCoreTable) => {
	const { classes } = useStyles();

	return (
		<Card className={classes.table} my={10}>
			<LoadingOverlay visible={loading || fetching} overlayBlur={0.5} />
			<ScrollArea offsetScrollbars type="auto">
				<Table highlightOnHover>
					<thead>
						<tr>
							{headerColumns.map(
								(headerColumn: IFHeaderColumn) => (
									<th key={headerColumn.id}>
										{headerColumn.title}
									</th>
								)
							)}
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</Table>
			</ScrollArea>

			{handlePagination && totalResults && perPage && lastPage && (
				<>
					<Divider />
					<Box className={classes.pagination} my={20}>
						<Text fz="sm" fw={500}>
							{from} to {to} - of {totalResults}
						</Text>
						<Pagination
							size="md"
							value={page}
							onChange={handlePagination}
							total={lastPage}
						/>
					</Box>
				</>
			)}
		</Card>
	);
};

export default CoreTable;
