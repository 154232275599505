import { TextInput, Button, Text, Card, Space, Flex, Anchor } from "@mantine/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForgotPassword } from "services/enteties/auth";
import { ForgotPasswordData } from "types/auth";
import { IconCheck, IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";

const ForgotPasswordForm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ForgotPasswordData>({
		resolver: yupResolver(
			yup.object({
				email: yup
					.string()
					.email("Must be valid email")
					.required("Email is required"),
			})
		),
	});
	const { mutate: resetPassword, isLoading } = useForgotPassword();

	const onSubmit = ({ email }: ForgotPasswordData) => {
		resetPassword(
			{ email },
			{
				onSuccess: (data) => {
					showNotification({
						title: "Success",
						icon: <IconCheck />,
						color: "green",
						message: data.data.status,
					});
				},
				onError: () => {
					showNotification({
						title: "Error",
						icon: <IconX />,
						message:
							"We could not send password reset link to your email",
					});
				},
			}
		);
	};

	return (
		<Card sx={{ width: 350, margin: "100px auto" }} radius="md" withBorder>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Text fz="lg">Forgot Password</Text>
				<Text fz="xs" my={10}>
					Enter your account email address and a password reset link
					will be send.
				</Text>
				<TextInput
					label="Email"
					{...register("email")}
					placeholder="Email"
					my={10}
					error={errors.email?.message as string}
				/>
				<Button
					type="submit"
					variant="gradient"
					loading={isLoading}
					fullWidth
				>
					Send password reset link
				</Button>
			</form>
      <Space h={"md"} />
			<Flex justify={"center"}>
				<Anchor size={"sm"} href="/login">
					Back to login
				</Anchor>
			</Flex>
		</Card>
	);
};

export default ForgotPasswordForm;
