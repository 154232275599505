import React from "react";
import { TextInput } from "@mantine/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useCreateFeature } from "services/enteties/sponsorships/sponsorship-feature";
import { IconPlus } from "@tabler/icons";
const CreateFeature = () => {
  const { mutate: createFeature } = useCreateFeature();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object({
        name: yup.string().required("Required field"),
      })
    ),
  });
  const handleCreateFeature = ({ name }: any) => {
    createFeature(
      { name },
      {
        onSuccess: () => {
          showNotification({
            title: "Success",
            message: "Successfully created",
          });
        },
      }
    );
  };
  return (
    <form onSubmit={handleSubmit(handleCreateFeature)}>
      <Box
        sx={{
          display: "flex",
          alignItems: errors.name?.message ? "center" : "end",
        }}
        mt={20}
      >
        <TextInput
          w="100%"
          label="Create new feature"
          placeholder="Create new feature"
          {...register("name")}
          error={errors.name?.message as string}
        />
        <Button
          rightIcon={<IconPlus size="1.2rem" />}
          variant="light"
          type="submit"
          mt={20}
          ml={10}
        >
          Create
        </Button>
      </Box>
    </form>
  );
};

export default CreateFeature;
