import { useContext } from "react";
import HotelContext from "context/HotelContext";
import { Box, Flex, Card, ActionIcon, Button, Text } from "@mantine/core";
import { IconTrash, IconLocation } from "@tabler/icons";

const HowToGetCard = ({ details, id }: any) => {
  const { deleteHowToGetThere } = useContext(HotelContext);
  return (
    <Card
      mih={240}
      radius="md"
      shadow="md"
      withBorder
      fz="sm"
      sx={{ position: "relative" }}
    >
      <ActionIcon
        color="red"
        sx={{ position: "absolute", top: 10, right: 10 }}
        onClick={() => deleteHowToGetThere(id)}
      >
        <IconTrash size={18} />
      </ActionIcon>
      <Flex mt={20} gap={5}>
        <Text fw={500}>{details?.transportation_type}</Text>
        {details?.time_to_destination && <Text>- {details.time_to_destination} min.</Text>}
      </Flex>
      {details?.action_text ? (
        <Button
          my={20}
          leftIcon={<IconLocation size={15} />}
          radius="xl"
          size="xs"
        >
          {details?.action_text}
        </Button>
      ) : (
        <Box mih={100} />
      )}

      <Text fz="sm" c="blue" sx={{ marginTop: "auto" }}>
        {details?.action_url}
      </Text>
      <Text>{details?.description}</Text>
    </Card>
  );
};

export default HowToGetCard;
