import { useQuery } from "@tanstack/react-query";
import http from "services/http";

export const useGetNewsletterSubscribers = (
	page: number,
	searchQuery: string = ""
) => {
	let queryParams = `page=${page}`;
	if (searchQuery) {
		queryParams += `&filter[email]=${searchQuery}`;
	}

	return useQuery(
		["newsletter-subscribers", page, searchQuery],
		() => http.get(`/api/newsletter/subscribers?${queryParams}`),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			select(data) {
				return data.data;
			},
		}
	);
};
