import { createContext, PropsWithChildren, useState } from "react";

interface IFEventContext {
  startDate: string | null;
  endDate: string | null;
  nextEvent: string;
  upcoming: string;
  handleSetEndDate: (date: any) => void;
  handleSetStartDate: (date: any) => void;
  handleNextEvent: (value: any) => void;
  handleUpcoming: (date: any) => void;
  resetEventContext: () => void;
}
const EventContext = createContext({} as IFEventContext);
export const EventContextProvider = ({ children }: PropsWithChildren) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [nextEvent, setNextEvent] = useState("no");
  const [upcoming, setUpcoming] = useState("no");

  const handleSetStartDate = (date: any) => {
    setStartDate(date);
  };

  const handleSetEndDate = (date: any) => {
    setEndDate(date);
  };
  const handleUpcoming = (value: string) => {
    setUpcoming(value);
  };
  const handleNextEvent = (value: string) => {
    setNextEvent(value);
  };
  const resetEventContext = () => {
    setUpcoming("no");
    setNextEvent("no");
    setStartDate(null);
    setEndDate(null);
  };
  return (
    <EventContext.Provider
      value={{
        startDate,
        endDate,
        nextEvent,
        upcoming,
        handleSetEndDate,
        handleSetStartDate,
        handleNextEvent,
        handleUpcoming,
        resetEventContext,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventContext;
