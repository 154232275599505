import { Grid, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useFormContext, useController } from "react-hook-form";
import { IconCalendarEvent } from "@tabler/icons";

const TicketForm = () => {
	const typeOptions = [
		{ value: "affiliate", label: "Affiliate" },
		{ value: "normal", label: "Normal" },
	];
	const isAvailableOptions = [
		{ value: "1", label: "Available" },
		{ value: "0", label: "Unavailable" },
	];

	const { field: typeField } = useController({
		name: "type",
	});

	const { field: startDateField } = useController({
		name: "start_date",
		defaultValue: new Date(),
	});

	const { field: endDateField } = useController({
		name: "end_date",
		defaultValue: new Date(),
	});

	const { field: isAvailableField } = useController({
		name: "is_available",
		defaultValue: "1",
	});

	const handleChangeType = (value: string) => {
		typeField.onChange(value);
	};

	const handleSetStartDate = (date: any) => {
		startDateField.onChange(date);
	};

	const handleSetEndDate = (date: any) => {
		endDateField.onChange(date);
	};
	const handleChangeIsAvailable = (status: string) => {
		isAvailableField.onChange(status);
	};

	const {
		register,
		getValues,
		formState: { errors },
	} = useFormContext();

	const ticketDetailsForm = [
		{
			input: (
				<Grid>
					<Grid.Col md={6} lg={4}>
						<TextInput
							withAsterisk
							placeholder="Ticket name"
							{...register("name")}
							label="Name"
							error={errors.name?.message as string}
						/>
					</Grid.Col>
					<Grid.Col md={6} lg={4}>
						<TextInput
							placeholder="Price"
							{...register("price")}
							label="Price"
							error={errors.name?.message as string}
						/>
					</Grid.Col>
					<Grid.Col md={6} lg={4}>
						<Select
							withAsterisk
							value={typeField.value}
							data={typeOptions}
							onChange={handleChangeType}
							label="Type"
							placeholder="Ticket type"
							error={errors.is_next_event?.message as string}
						/>
					</Grid.Col>
				</Grid>
			),
			span: {
				lg: 12,
				md: 12,
			},
		},
		{
			input: (
				<Grid>
					<Grid.Col md={6} lg={4}>
						<DateInput
							value={startDateField.value ? new Date(startDateField.value) : null}
							onChange={handleSetStartDate}
							label="Start date"
							icon={<IconCalendarEvent />}
							placeholder="Start date"
							allowDeselect
						/>
					</Grid.Col>
					<Grid.Col md={6} lg={4}>
						<DateInput
							minDate={ new Date(getValues('start_date')) ?? startDateField.value}
							value={endDateField.value ? new Date(endDateField.value) : null}
							onChange={handleSetEndDate}
							label="End date"
							icon={<IconCalendarEvent />}
							placeholder="End date"
							allowDeselect
						/>
					</Grid.Col>
					<Grid.Col md={6} lg={4}>
						<Select
							withAsterisk
							data={isAvailableOptions}
							value={isAvailableField.value}
							onChange={handleChangeIsAvailable}
							label="Status"
							placeholder="Available"
							error={errors.is_next_event?.message as string}
						/>
					</Grid.Col>
				</Grid>
			),
			span: {
				lg: 12,
				md: 12,
			},
		},
	];

	return (
		<Grid justify="space-around" p={10} h={500} >
			{ticketDetailsForm.map((ticket: any, key: number) => (
				<Grid.Col md={ticket.span.md} lg={ticket.span.lg} key={key}>
					{ticket.input}
				</Grid.Col>
			))}
		</Grid>
	);
};
export default TicketForm;
