import { useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DataTable } from "mantine-datatable";
import {
	Box,
	Flex,
	Text,
	TextInput,
	Button,
	Alert,
	Tabs,
	Image,
	Skeleton,
	createStyles,
	rem,
} from "@mantine/core";
import { useGetSponsorshipMain } from "services/enteties/sponsorships";
import { useGetSponsorshipBase } from "services/enteties/sponsorships/sponsorship-package";
import { useGetSponsorshipFeatures } from "services/enteties/sponsorships";
import { useUpdateIndividualValue } from "services/enteties/sponsorships";
import CoreTable from "components/core/CoreTable";
import CoreModal from "components/core/CoreModal";
import { showNotification } from "@mantine/notifications";
import UpdateSponsorship from "./UpdateSponsorship";
import PageWrapper from "components/core/PageWrapper";
import {
	IconX,
	IconCheck,
	IconAlertCircle,
	IconSettings,
	IconArrowBackUp,
	IconCopy,
} from "@tabler/icons";
import { useNavigate, useParams } from "react-router-dom";
import DuplicateSponsorship from "./DuplicateSponsorship";
const logo = require("../../images/logo-black.png");

const colors = {
	headlining: "#0D9488",
	diamond: "#1e40af",
	platinum: "#a21caf",
	gold: "#dc2626",
	silver: "#4b5563",
	"meet-market": "#0ea5e9",
	null: "#64748B",
}

const useStyles = createStyles((theme) => ({
	idColumnCells: {
		backgroundColor: theme.colors.gray[1],
		color: theme.colors.gray[7],
		fontWeight: 500,
		[`td:hover`]: {
			backgroundColor: theme.colors.gray[8],
		},
	},
	header: {
		height: 90,
	},
	table: {
		"tr:nth-child(odd) td": {
			backgroundColor: theme.colors.gray[1],
		},
		"tr:nth-child(even) td": {
			backgroundColor: theme.colors.gray[3],
		},
		"tr td:hover ": {
			backgroundColor: theme.colors.gray[5],
		},
	},
}));
const Sponsorship = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const handlePagination = (page: number) => setPage(page);
	
	const { data: mainData, isLoading: loadMainSponsorships } =
		useGetSponsorshipMain(id);
	const { data: baseData, isLoading, isFetching } = useGetSponsorshipBase(id, page);
	const { data: features }: any = useGetSponsorshipFeatures();

	const { mutate } = useUpdateIndividualValue();

	const { classes } = useStyles();


	const [sponsorIndex, setSponsorIndex] = useState<any>(null);
	const [featureIndex, setFeatureIndex] = useState(null);
	const [disabledState, setDisabledState] = useState(false);

	const [featureName, setFeatureName] = useState("");
	const [column, setColumn] = useState<any>({});

	const [editSponsorship, setEditSponsorship] = useState(false);
	const [isOpenClone, setIsOpenClone] = useState(false);

	const headerColumns = [
		{ id: 1, title: "id" },
		{ id: 2, title: "Name" },
		{ id: 3, title: "Price" },
		{ id: 4, title: "Currency" },
		{ id: 5, title: "Quantity" },
		{ id: 6, title: "Amount sold" },
		{ id: 7, title: "Amount reserved" },
		{ id: 8, title: "Start date" },
	];

	const rows = baseData?.data?.map((base: any, key: number) => (
		<tr key={`baseSponsorship-${key}`}>
			<td>{base.id}</td>
			<td>{base.name}</td>
			<td>{base.price / 100 || "free"}</td>
			<td>{base.currency}</td>
			<td>{base.quantity}</td>
			<td>{base.amount_sold}</td>
			<td>{base.amount_reserved}</td>
			<td>
				{base.created_at
					? new Date(base.created_at).toLocaleDateString()
					: ""}
			</td>
		</tr>
	));
	const { handleSubmit, setValue, reset, register } = useForm({
		resolver: yupResolver(
			yup.object({
				value: yup.string().required("Required field"),
				// featureName: yup.object().required("Required field"),
			})
		),
	});

	const clickHandler = ({
		columnIndex: sponsorIndex,
		column,
		record,
	}: any) => {
		if (sponsorIndex !== 0) {
			setFeatureIndex(record?.feature_id);
			setSponsorIndex(parseInt(column.accessor));
			setColumn(column);
			setFeatureName(record.name);

			const target = parseInt(column.accessor);
			const inputValue = Object.fromEntries(
				Object.entries(record).filter(([key]) => +key === target)
			);

			if (Object.values(inputValue)[0] === "not-enabled") {
				setDisabledState(true);
				return;
			}
			if (typeof Object.values(inputValue)[0] !== "string") {
				const value: any = Object.values(inputValue)?.[0];
				value.props.color === "green"
					? setValue("value", "yes")
					: setValue("value", "no");
				return;
			}
			setValue("value", Object.values(inputValue));
		} else {
			setEditSponsorship(true);
		}
	};

	const result = useMemo(
		() =>
			features?.map((property: any) => {
				const matchingFeatures = mainData
					?.map((data: any) => {
						const feature = data?.features?.find(
							(feature: any) => feature.id === property.id
						);

						if (feature) {
							return {
								id: data.id,
								values: feature.value,
								feature_id: feature.id,
							};
						}
						return { id: data.id, values: "not-enabled" };
					})
					?.filter((match: any) => match !== null);
				return {
					id: property.id,
					name: property.name,
					matches: matchingFeatures,
				};
			}),
		[features, mainData]
	);

	const potentialHeaders = mainData?.map((data: any, key: number) => ({
		accessor: data.id.toString(),
		title: (
			<>
				<Box>
					<Text
						sx={{
							color: "white",
							textTransform: "uppercase",
							whiteSpace: "initial",
							fontWeight: 700,
							backgroundColor: "rgba(0,0,0,0.4)",
							fontSize: 14,
							width: "100%",
							padding: 0,
						}}
					>
						{data.name}
					</Text>
				</Box>
				<Text
					sx={{ fontWeight: 600, color: "white", fontSize: 14 }}
					mt={5}
				>
					{data.price / 100} {data.currency}
				</Text>
			</>
		),
		textAlignment: "center",
		cellsClassName: classes.idColumnCells,
		titleClassName: classes.header,
		titleStyle: {
			backgroundColor: colors[data.color_scheme as keyof typeof colors]?.toString() ?? colors.null,
			border: "5px solid white",
			padding: 0,
			width: 50,
		},
		cellsStyle: {
			border: "5px solid white",
		},
	}));

	const displayData = useMemo(
		() =>
			result?.map(({ matches, name }: any) =>
				matches?.reduce(
					(acc: any, { id, values, feature_id }: any) => ({
						...acc,
						name: name,
						[id]:
							values === "yes" ? (
								<IconCheck size={18} color="green" />
							) : values === "no" ? (
								<IconX size={18} color="red" />
							) : (
								values
							),
						feature_id,
					}),
					{}
				)
			),
		[result]
	);

	potentialHeaders?.unshift({
		accessor: "name",
		title: (
			<Flex direction="column" align="center">
				<Image mx="auto" width={120} src={logo} alt="logo" />
				<Text
					sx={{
						textTransform: "uppercase",
						fontSize: 14,
						fontWeight: 500,
						marginTop: 5,
					}}
				>
					Package name
				</Text>
			</Flex>
		),
		cellsStyle: {
			border: "5px solid white",
		},
		titleSx: (theme: any) => ({
			backgroundColor: theme.colors.gray[4],
			border: "5px solid white",
			width: 160,
		}),
		cellsSx: (theme: any) => ({
			color: theme.colors.gray[7],
			titleClassName: classes.header,
		}),
	});

	const updateIndividualSponsorship = ({ value }: any) => {
		mutate(
			{ value, featureIndex, sponsorIndex },
			{
				onSuccess: () => {
					showNotification({
						title: "Success",
						message: "Successfully updated",
					});
					resetState();
				},
			}
		);
	};

	const resetState = () => {
		setFeatureIndex(null);
		setSponsorIndex(null);
		setColumn({});
		setDisabledState(false);
		reset();
	};

	return (
		<PageWrapper title="Sponsorship comparison">
			<CoreModal
				opened={
					featureIndex !== null &&
					sponsorIndex !== null &&
					sponsorIndex !== 0
				}
				size={450}
				onClose={resetState}
				title={`Edit feature`}
			>
				{!disabledState ? (
					<Box>
						<form
							onSubmit={handleSubmit(updateIndividualSponsorship)}
						>
							<Text
								w="60%"
								ta="center"
								mx="auto"
								bg={column?.titleStyle?.backgroundColor ?? colors.null}
							>
								{column?.title}
							</Text>
							<Alert
								w="90%"
								mx="auto"
								icon={<IconAlertCircle size="1rem" />}
								title="Notification!"
								my={20}
							>
								Please use <b>yes</b> for (
								<IconCheck size={18} color="green" />) for and{" "}
								<b>No</b> for sign (
								<IconX size={18} color="red" />)
							</Alert>

							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
								mt={20}
							>
								<TextInput
									label={featureName}
									miw="90%"
									{...register("value")}
									placeholder="Input value"
								/>
							</Box>
							<Flex justify="space-around" mt={25} mb={10}>
								<Button
									radius="xl"
									bg="#0ea5e9"
									type="submit"
									size="md"
								>
									Update value
								</Button>
								<Button
									onClick={resetState}
									radius="xl"
									variant="subtle"
									size="md"
								>
									Close
								</Button>
							</Flex>
						</form>
					</Box>
				) : (
					<Alert
						icon={<IconAlertCircle size="1rem" />}
						title="Notification!"
					>
						Please add this feature in <b>Update sponsorship</b>{" "}
						under <b>Sponsorship features</b> section first!
					</Alert>
				)}
			</CoreModal>

			<CoreModal
				title={`Sponsorships settings`}
				opened={editSponsorship}
				onClose={() => setEditSponsorship(false)}
				size={"60%"}
			>
				<UpdateSponsorship id={id} />
			</CoreModal>

			{/* Modal Clone package */}
			<DuplicateSponsorship
				eventId={id}
				isOpen={isOpenClone}
				setIsOpen={setIsOpenClone}
			/>

			<Tabs color="gray" variant="pills" radius="xl" defaultValue="1">
				<Tabs.List mt={20}>
					<Tabs.Tab value="1">Main Sponsorship</Tabs.Tab>
					<Tabs.Tab value="2">Base Sponsorship</Tabs.Tab>
					<Flex justify="end" ml="auto" gap={"sm"}>
						<Button
							rightIcon={<IconSettings size={20} />}
							variant="outline"
							onClick={() => setEditSponsorship(true)}
						>
							Sponsorships settings
						</Button>
						<Button
							variant="outline"
							rightIcon={<IconCopy size={rem(18)} />}
							onClick={() => setIsOpenClone(true)}
						>
							Clone package
						</Button>
						<Button
							onClick={() => navigate("/sponsorships")}
							leftIcon={<IconArrowBackUp size={rem(18)} />}
						>
							Back
						</Button>
					</Flex>
				</Tabs.List>
				<Tabs.Panel value="1" pt="xs" mt={20}>
					{loadMainSponsorships ? (
						<Skeleton height={500} />
					) : (
						<DataTable
							verticalAlignment="center"
							className={classes.table}
							onCellClick={clickHandler}
							columns={potentialHeaders ?? []}
							records={displayData ?? []}
							minHeight={300}
							sx={{ width: "90%", margin: "auto", padding: 8 }}
						/>
					)}
				</Tabs.Panel>
				<Tabs.Panel value="2" pt="xs" mt={10}>
					<CoreTable
						rows={rows}
						page={page}
						headerColumns={headerColumns}
						loading={isLoading}
						fetching={isFetching}
						perPage={baseData?.meta?.per_page}
						totalResults={baseData?.meta?.total}
						from={baseData?.meta?.from}
						to={baseData?.meta?.to}
						lastPage={baseData?.meta?.last_page}
						handlePagination={handlePagination}
					/>
				</Tabs.Panel>
			</Tabs>
		</PageWrapper>
	);
};

export default Sponsorship;
