import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateIndividualValue = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ value, sponsorIndex, featureIndex }: any) =>
      http.post(`/api/sponsorships/${sponsorIndex}/features/${featureIndex}`, {
        _method: "PATCH",
        value,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["sponsorships-main"]);
    },
  });
};
