import { Flex, Loader, Select, TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import CoreTable from "components/core/CoreTable";
import PageWrapper from "components/core/PageWrapper";
import { debounce } from "lodash";
import { useState } from "react";
import { useLogs } from "services/enteties/email";
import { useGetAllEventsContextMenu } from "services/enteties/events/useGetAllEventsContextMenu";
import { EmailLog } from "types/emailLog";
import { Event } from "types/event";

const Email = () => {
	const [searchQuery, setSearchQuery] = useState("");
	const [page, setPage] = useState(1);
	const handlePagination = (page: number) => setPage(page);
	const [selectedEvent, setSelectedEvent] = useState<string>("");
	const { data, isLoading, isFetching, refetch } = useLogs(
		page,
		searchQuery,
		selectedEvent
	);

	// Get events and map id and name
	const { data: eventsData, isLoading: isLoadingEvents } =
		useGetAllEventsContextMenu();
	const events = eventsData?.map((e: Event) => ({
		value: e.id,
		label: e.name,
	}));

	const handleEventChange = (value: string) => {
		setSelectedEvent(value);
		if (selectedEvent) refetch();
	};

	const debouncedSearch = debounce((value: string) => {
		setSearchQuery(value);
	}, 500);

	const handleSearchLogs = () => (
		<Flex align="center" gap="xl">
			<Select
				placeholder="Select event"
				allowDeselect
				disabled={isLoadingEvents}
				icon={isLoadingEvents && <Loader size="sm" />}
				data={events ?? []}
				dropdownPosition="bottom"
				onChange={handleEventChange}
				searchable
			/>
			<TextInput
				placeholder="Search"
				onChange={(event) => debouncedSearch(event.target.value)}
				icon={<IconSearch size={"0.8rem"} />}
			/>
		</Flex>
	);

	const headerColumns = [
		{ id: 1, title: "id" },
		{ id: 2, title: "Summit" },
		{ id: 3, title: "Date" },
		{ id: 4, title: "Email From" },
		{ id: 5, title: "Email To" },
		{ id: 6, title: "Badge From" },
		{ id: 7, title: "Badge To" },
		{ id: 8, title: "Subject" },
		{ id: 9, title: "Message" },
	];

	let rows = null;
	if (data?.data?.length) {
		rows = data?.data?.map((info: EmailLog, key: number) => (
			<tr key={`${info.id}-${key}`}>
				<td>{info?.id}</td>
				<td>{info.event?.name}</td>
				<td>{new Date(info.created_at).toLocaleDateString()}</td>
				<td>{info?.from?.email}</td>
				<td>{info?.to?.email}</td>
				<td>{info.from?.badge_name}</td>
				<td>{info?.to?.badge_name}</td>
				<td>{info?.subject}</td>
				<td>{info?.message}</td>
			</tr>
		));
	} else {
		rows = (
			<tr>
				<td
					colSpan={headerColumns.length}
					style={{
						textAlign: "center",
						padding: "20px 0px",
					}}
				>
					No results.
				</td>
			</tr>
		);
	}

	return (
		<PageWrapper title="Email Logs" action={handleSearchLogs()}>
			<CoreTable
				rows={rows}
				page={page}
				headerColumns={headerColumns}
				perPage={data?.meta?.per_page}
				totalResults={data?.meta?.total}
				from={data?.meta?.from}
				to={data?.meta?.to}
				lastPage={data?.meta?.last_page}
				loading={isLoading}
				fetching={isFetching}
				handlePagination={
					data?.data?.length > 0 ? handlePagination : undefined
				}
			/>
		</PageWrapper>
	);
};

export default Email;
