import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDuplicateSponsorship = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      sponsorshipId,
      eventId,
      selectedEventId,
    }: {
      sponsorshipId: string | null;
      eventId: string | undefined | null;
      selectedEventId: string | undefined | null;
    }) => {
      if (eventId && sponsorshipId) {
        return http.post(`/api/sponsorships/${eventId}/duplicate`, {
          sponsorship_id: sponsorshipId,
          event_id: sponsorshipId === "0" ? selectedEventId : null,
        });
      } else return Promise.resolve(null);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["sponsorships-details"]);
      queryClient.invalidateQueries(["sponsorships-all"]);
      queryClient.invalidateQueries(["sponsorships-main"]);
      queryClient.invalidateQueries(["sponsorships-base"]);
    },
  });
};
