import { useState, useMemo } from "react";
import { Button, Checkbox, LoadingOverlay } from "@mantine/core";
import { useGetSponsorshipFeatures } from "services/enteties/sponsorships";
import { useSyncFeatures } from "services/enteties/sponsorships/sponsorship-feature";
import { showNotification } from "@mantine/notifications";
const SponsorshipFeatureHandler = ({ data, loading, sponsorshipId }: any) => {
  const [value, setValues] = useState<any>([]);
  const [available, setAvailable] = useState<any>([]);

  const { data: allFeatures } = useGetSponsorshipFeatures();
  const { mutate: updateFeatures } = useSyncFeatures();
  useMemo(() => setAvailable(data?.features), [data]);

  useMemo(() => {
    const result = allFeatures
      ?.filter((item1: any) =>
        new Set(data?.features.map((item2: any) => item2.id)).has(item1.id)
      )
      ?.map((item1: any) => item1.id.toString());
    setValues(result);
  }, [data, allFeatures]);

  const checkCheckboxes = useMemo(
    () =>
      available
        ?.filter((item1: any) => value.includes(item1.id.toString()))
        .concat(
          value
            ?.filter(
              (num: any) =>
                !available.find((item1: any) => item1.id.toString() === num)
            )
            ?.map((num: any) => ({ id: parseInt(num), value: "1" }))
        ),
    [available, value]
  );
  const updateValue = () => {
    const values = checkCheckboxes?.map((value: any) => ({
      id: value.id,
      value: value.value,
    }));
    updateFeatures(
      { id: sponsorshipId, data: values },
      {
        onSuccess: () => {
          showNotification({
            title: "Success",
            message: "Successfully created",
          });
        },
        onError: (error: any) => {
          showNotification({
            color: "red",
            title: "Error",
            message: error.response.data.message,
          });
        },
      }
    );
  };

  return (
    <>
      <Checkbox.Group value={value} onChange={setValues}>
        <LoadingOverlay visible={loading} />
        {allFeatures?.map((feature: any, key: number) => (
          <Checkbox
            value={feature.id.toString()}
            my={10}
            label={feature.name}
            key={key}
          />
        ))}
      </Checkbox.Group>
      <Button onClick={updateValue} my={20}>
        Update
      </Button>
    </>
  );
};

export default SponsorshipFeatureHandler;
