import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteParticipant = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (id: any) =>
			http.post(`/api/participants/${id}`, { _method: "DELETE" }),
		onSuccess: () => {
			queryClient.invalidateQueries(["participants"]);
		},
	});
};
