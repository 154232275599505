import http from "services/http";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { TicketData } from "types/ticket";

export const useAllTickets = ():UseQueryResult<TicketData[]> => {
	return useQuery(["tickets"], () => http.get(`/api/tickets`), {
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		select(data) {
			return data.data;
		},
	});
};
