import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useFilterVenue = (query: any) => {
  return useQuery(
    ["hotels", query],
    () => http.get(`/api/hotels?filter[type]=venue&[name]=${query}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!query,
      select(data) {
        return data.data.data;
      },
    }
  );
};
