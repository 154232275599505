import http from "services/http";
import { useQuery } from "@tanstack/react-query";

export const useLogs = (
	page: number,
	searchQuery: string = "",
	eventFilter?: string
) => {
	let queryParams = `page=${page}`;

	if (searchQuery) {
		queryParams += `&filter[keyword]=${searchQuery}`;
	}

	if (eventFilter) {
		queryParams += `&filter[event_id]=${eventFilter}`;
	}

	return useQuery(
		["email-logs", page, searchQuery, eventFilter],
		() => http.get(`/api/participants/log?${queryParams}`),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			select(data) {
				return data.data;
			},
		}
	);
};
