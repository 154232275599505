import { MantineThemeOverride, MantineTheme } from "@mantine/core";

const defaultTheme: MantineThemeOverride = {
  globalStyles: (theme: MantineTheme) => ({
    "*": {
      boxSizing: "border-box",
      padding: 0,
      margin: 0,
      fontFamily: "Greycliff CF, sans-serif",
    },
  }),
  colors: {
    customGray: ["#3c4b64", "", "", "", "", "", "#3c4b64", "", "", ""],
    customBlue: ["#F7F9FB", "", "", "", "", "", "", "", "", ""],
  },
  components: {
    Tabs: {
      styles: (theme: MantineTheme) => ({
        tab: {
          fontWeight: 500,
          radius: 300,
          marginRight: 10,
        },
      }),
    },
    AppShell: {
      styles: (theme: MantineTheme) => ({
        main: {
          backgroundColor: theme.colors.customBlue[0],
        },
      }),
    },
    Accordion: {
      styles: (theme: MantineTheme) => ({
        control: {
          border: "1px solid #EAEAED",
          backgroundColor: theme.colors.gray[1],
        },
      }),
    },
    Dropzone: {
      styles: (theme: MantineTheme) => ({
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }),
    },
    Modal: {
      styles: (theme) => ({
        modal: {
          borderRadius: 20,
          [theme.fn.smallerThan("sm")]: {
            width: "100%",
            height: "100%",
            margin: "20px 5px 0px 5px",
            borderRadius: "30px 30px 0px 0px",
          },
        },
        inner: {
          [theme.fn.smallerThan("sm")]: {
            padding: 0,
          },
        },
        overlay: {
          [theme.fn.smallerThan("sm")]: {
            display: "none",
          },
        },
        header: { margin: "0px 0px 25px 0px" },
        title: {
          marginLeft: "auto",
          marginRight: "auto",
          fontWeight: 600,
          fontSize: 20,
          paddingTop: 10,
          color: theme.colors.gray,
          [theme.fn.smallerThan("sm")]: {
            fontSize: theme.fontSizes.lg,
          },
        },

        close: {
          width: 30,
          height: 30,
          backgroundColor: "rgba(61, 64, 91, 0.08)",
          color: theme.colors.gray,
          position: "absolute",
          right: 20,
          top: 20,
          "svg:not(:root)": { width: 19, height: 19 },
        },
      }),
    },
  },
};
export default defaultTheme;
