import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useExportSponsorships = () => {
  return useQuery(
    ["sponsorships-export"],
    () => http.get(`/api/sponsorships/export`),
    {
      select(data) {
        return data.data;
      },
    }
  );
};
