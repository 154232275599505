import http from "services/http";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { LoginData } from "types/auth";

export const useLogin = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async ({ email, password }: LoginData) => {
      await http.get("/sanctum/csrf-cookie");
      return await http.post("/login", {
        email,
        password,
      });
    },
    onSuccess: () => {
      navigate("/admin");
    },
    onError: (error: any) => {
      if (error?.response?.data) {
        showNotification({
          title: "Error",
          message: error?.response?.data?.message,
        });
      }
    },
  });
};
