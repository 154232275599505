import { Flex, TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import CoreTable from "components/core/CoreTable";
import PageWrapper from "components/core/PageWrapper";
import { debounce } from "lodash";
import { useState } from "react";
import { useGetNewsletterSubscribers } from "services/enteties/newsletter";
import { NewsletterSubscriber } from "types/NewsletterSubscriber";

const Newsletter = () => {
	const [searchQuery, setSearchQuery] = useState("");
	const [page, setPage] = useState(1);
	const handlePagination = (page: number) => setPage(page);
	const { data, isLoading, isFetching } = useGetNewsletterSubscribers(
		page,
		searchQuery
	);

	const debouncedSearch = debounce((value: string) => {
		setSearchQuery(value);
	}, 500);

	const handleSearch = () => (
		<Flex align="center" gap="xl">
			<TextInput
				placeholder="Search"
				onChange={(event) => debouncedSearch(event.target.value)}
				icon={<IconSearch size={"0.8rem"} />}
			/>
		</Flex>
	);

	const headerColumns = [
		{ id: 1, title: "id" },
		{ id: 2, title: "Email" },
		{ id: 3, title: "Created at" },
		{ id: 4, title: "Updated at" },
	];

	let rows = null;
	if (data?.data?.length) {
		rows = data?.data?.map(
			(subscriber: NewsletterSubscriber, key: number) => (
				<tr key={`${subscriber.id}-${key}`}>
					<td>{subscriber?.id}</td>
					<td>{subscriber?.email}</td>
					<td>
						{new Date(subscriber?.created_at).toLocaleDateString()}
					</td>
					<td>
						{new Date(subscriber?.updated_at).toLocaleDateString()}
					</td>
				</tr>
			)
		);
	} else {
		rows = (
			<tr>
				<td
					colSpan={headerColumns.length}
					style={{
						textAlign: "center",
						padding: "20px 0px",
					}}
				>
					No results.
				</td>
			</tr>
		);
	}

	return (
		<PageWrapper title="Newsletter subscribers" action={handleSearch()}>
			<CoreTable
				rows={rows}
				page={page}
				headerColumns={headerColumns}
				perPage={data?.per_page}
				totalResults={data?.total}
				from={data?.from}
				to={data?.to}
				lastPage={data?.last_page}
				loading={isLoading}
				fetching={isFetching}
				handlePagination={
					data?.data?.length > 0 ? handlePagination : undefined
				}
			/>
		</PageWrapper>
	);
};

export default Newsletter;
