import { Button, Grid, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useForm } from "react-hook-form";
import { useEditParticipant } from "services/enteties/participants/useEditParticipant";
import { Participant } from "types/participant";

interface EditParticipantsProps {
  participant: Participant | null;
}

const EditParticipant = ({ participant }: EditParticipantsProps) => {
  const { mutate } = useEditParticipant();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Participant>({
    defaultValues: {
      id: participant?.id,
      name: participant?.name,
      badge_name: participant?.badge_name,
      whova_confirmation_code: participant?.whova_confirmation_code,
      email: participant?.email,
      company_badge_name: participant?.company_badge_name,
      answers: {
        my_main_activity: participant?.answers?.my_main_activity,
        my_main_vertical: participant?.answers?.my_main_vertical,
        which_other_verticals_are_you_also_active_in:
          participant?.answers?.which_other_verticals_are_you_also_active_in,
        which_link_may_attendees_use_to_schedule_a_personal_meeting_with_you_during_tes:
          participant?.answers
            .which_link_may_attendees_use_to_schedule_a_personal_meeting_with_you_during_tes,
        i_am_offering: participant?.answers?.i_am_offering,
        i_am_looking_for: participant?.answers?.i_am_looking_for,
        my_domains: participant?.answers?.my_domains,
      },
    },
  });

  const onSubmit = (data: any) => {
    mutate(data, {
      onSuccess: () => {
        showNotification({
          title: "Success",
          message: "Successfully updated",
        });
      },
      onError: (error: any) => {
        showNotification({
          title: "Error",
          message: error.response.data.message,
          color: "red",
        });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Col span={6}>
          <TextInput
            label="Name"
            {...register("name")}
            error={errors.name?.message}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label="Badge name"
            {...register("badge_name")}
            error={errors.badge_name?.message}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label="Whova confirmation code"
            {...register("whova_confirmation_code")}
            error={errors.whova_confirmation_code?.message}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label="Email"
            {...register("email")}
            error={errors.email?.message}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label="Company"
            {...register("company_badge_name")}
            error={errors.company_badge_name?.message}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label="My main activity"
            {...register("answers.my_main_activity")}
            error={errors.answers?.my_main_activity?.message}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label="My main vertical"
            {...register("answers.my_main_vertical")}
            error={errors.answers?.my_main_vertical?.message}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label="Which other verticals are you also active in"
            {...register(
              "answers.which_other_verticals_are_you_also_active_in"
            )}
            error={
              errors.answers?.which_other_verticals_are_you_also_active_in
                ?.message
            }
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label="I am offering"
            {...register("answers.i_am_offering")}
            error={errors.answers?.i_am_offering?.message}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label="I am looking for"
            {...register("answers.i_am_looking_for")}
            error={errors.answers?.i_am_looking_for?.message}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <TextInput
            label="My domains"
            {...register("answers.my_domains")}
            error={errors.answers?.my_domains?.message}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            pattern={"^(https?://.*|N/A)$"}
            title="Must be a URL, starting with 'http://' or 'https://' or 'N/A'"
            label="Which link may attendees use to schedule a personal meeting with you during TES"
            {...register(
              "answers.which_link_may_attendees_use_to_schedule_a_personal_meeting_with_you_during_tes"
            )}
            error={
              errors.answers
                ?.which_link_may_attendees_use_to_schedule_a_personal_meeting_with_you_during_tes
                ?.message
            }
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Button type="submit">Submit</Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default EditParticipant;
