import { Button, rem } from "@mantine/core";
import { IconPencil } from "@tabler/icons";
import CoreTable from "components/core/CoreTable";
import PageWrapper from "components/core/PageWrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllEvents } from "services/enteties/events/useGetAllEvents";

export default function SelectEvent() {
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const handlePagination = (page: number) => setPage(page);

	const { data, isLoading, isFetching } = useGetAllEvents(page);

	const headerColumns = [
		{ id: 1, title: "id" },
		{ id: 2, title: "Name" },
		{ id: 3, title: "Slug" },
		{ id: 4, title: "Created at" },
		{ id: 6, title: "Edit Sponsorship" },
	];
	const rows = data?.data?.map((event: any, key: number) => (
		<tr key={key}>
			<td>{event.id}</td>
			<td>{event.name}</td>
			<td>{event.slug}</td>
			<td>{new Date(event.created_at).toLocaleDateString()}</td>
			<td>
				<Button
					onClick={() => navigate(`/sponsorships/${event.id}`)}
					variant="subtle"
					size="xs"
					leftIcon={<IconPencil size={rem(18)} />}
				>
					Edit
				</Button>
			</td>
		</tr>
	));

	return (
		<PageWrapper title="Sponsorships: Select event">
			<CoreTable
				rows={rows}
				page={page}
				headerColumns={headerColumns}
				perPage={data?.meta?.per_page}
				totalResults={data?.meta?.total}
				from={data?.meta?.from}
				to={data?.meta?.to}
				lastPage={data?.meta?.last_page}
				loading={isLoading}
				fetching={isFetching}
				handlePagination={handlePagination}
			/>
		</PageWrapper>
	);
}
