import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetEvent = (eventId: string | undefined) => {
  return useQuery(
    ["events", eventId],
    () => http.get(`/api/events/${eventId}`),
    {
      select(data) {
        return data.data;
      },
    }
  );
};
