import { Select } from "@mantine/core";
import { useGetTimeZones } from "services/enteties/utilities";
import { useFormContext, Controller } from "react-hook-form";

const TimeZones = () => {
  const { data } = useGetTimeZones();
  const { control } = useFormContext();

  return (
    <Controller
      name="timezone"
      control={control}
      render={({ field }) => (
        <Select
          withAsterisk
          {...field}
          searchable
          placeholder="Time zone"
          label="Time zone"
          data={data ?? []}
        />
      )}
    />
  );
};

export default TimeZones;
