import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export const useDeleteHotel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: any) =>
      http.post(`/api/hotels/${id}`, { _method: "DELETE" }),
    onSuccess: () => {
      queryClient.invalidateQueries(["hotels"]);
    },
  });
};
