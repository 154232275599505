import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateIsPublicParticipant = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (reqData: any) => {
      const { id, ...data } = reqData;
      return http.post(`/api/participants/${id}`, {
        _method: "PATCH",
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["participants-all"]);
    },
  });
};
