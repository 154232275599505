import { Box, Button, Loader, Select } from "@mantine/core";
import CoreModal from "components/core/CoreModal";
import { useEffect, useState } from "react";
import { useGetAllEventsContextMenu } from "services/enteties/events/useGetAllEventsContextMenu";
import {
  useDuplicateSponsorship,
  useGetSponsorshipsByEventContextMenu,
} from "services/enteties/sponsorships/sponsorship-package";
import { Event } from "types/event";

export default function DuplicateSponsorship({
  eventId,
  isOpen,
  setIsOpen,
}: {
  eventId: string | undefined;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [packagesList, setPackagesList] = useState<
    { value: string; label: string }[]
  >([]);
  // Get events and map id and name
  const { data: eventsData, isLoading: isLoadingEvents } =
    useGetAllEventsContextMenu();
  const events = eventsData
    ?.filter((e: Event) => {
      return String(e.id) !== eventId;
    })
    ?.map((e: Event) => ({
      value: e.id,
      label: e.name,
    }));

  //Get packages from event
  const {
    data: packages,
    isLoading: isLoadingSponsorships,
    refetch,
  } = useGetSponsorshipsByEventContextMenu(selectedEvent);

  const handleEventChange = (value: string) => {
    setSelectedEvent(value);
    if (selectedEvent) refetch();
  };

  useEffect(() => {
    setSelectedPackage(null);
    setPackagesList([]);
    if (selectedEvent) {
      refetch();
    }
  }, [refetch, selectedEvent]);

  const { mutate: duplicateSponsorship, isLoading: isLoadingDuplicate } =
    useDuplicateSponsorship();

  const handleSubmit = () => {
    duplicateSponsorship({
      sponsorshipId: selectedPackage,
      eventId: eventId,
      selectedEventId: selectedEvent,
    });
  };

  useEffect(() => {
    if (packages) {
      if (packages.length > 1) {
        const updatedPackages = [
          {
            value: "0",
            label: "All",
          },
          ...packages,
        ];
        setPackagesList(updatedPackages);
      }
    }
  }, [packages]);

  return (
    <CoreModal
      title={`Clone sponsorship package`}
      opened={isOpen}
      onClose={() => {
        setIsOpen(false);
        setSelectedEvent(null);
        setSelectedPackage(null);
      }}
    >
      <Box
        sx={{
          height: 420,
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <Select
          label="Select event"
          disabled={isLoadingEvents}
          icon={isLoadingEvents && <Loader size="sm" />}
          data={events ?? []}
          dropdownPosition="bottom"
          onChange={handleEventChange}
          searchable
        />

        <Select
          label="Select package"
          disabled={isLoadingSponsorships || !selectedEvent}
          icon={
            isLoadingSponsorships && !!selectedEvent && <Loader size="sm" />
          }
          data={packages ? packagesList : []}
          dropdownPosition="bottom"
          value={selectedPackage}
          onChange={(value: any) => setSelectedPackage(value)}
          searchable
        />
        <Box sx={{ marginTop: 200, textAlign: "center" }}>
          <Button onClick={handleSubmit} loading={isLoadingDuplicate}>
            Clone package to this event
          </Button>
        </Box>
      </Box>
    </CoreModal>
  );
}
