import { useContext } from "react";
import { useAuthUser } from "services/enteties/auth/useAuthUser";
import { Flex, Loader } from "@mantine/core";
import AuthContext from "context/AuthContext";
import { Outlet } from "react-router-dom";
import CoreAppShell from "./CoreAppShell";
const PageGuard = () => {
  const { auth, user } = useContext(AuthContext);
  const { isLoading, status, fetchStatus } = useAuthUser();
  const queryIsDisabled = status === "loading" && fetchStatus === "idle";

  if (isLoading && !queryIsDisabled) {
    return (
      <Flex p={50}>
        <Loader variant="dots" m="auto" mt={50} size={100} />
      </Flex>
    );
  }

  if (auth && Object.keys(user)) {
    return (
      (
        <CoreAppShell>
          <Outlet />
        </CoreAppShell>
      ) ?? null
    );
  }
  return null;
};

export default PageGuard;
