import { useMemo, useState } from "react";
import { Box, Button, Select, TextInput } from "@mantine/core";
import { useUpdateFeature } from "services/enteties/sponsorships/sponsorship-feature";
import { Controller } from "react-hook-form";
import { useGetSponsorshipFeatures } from "services/enteties/sponsorships";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { IconTrash, IconPencil } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { useDeleteSponsorFeature } from "services/enteties/sponsorships/sponsorship-feature";
import CreateFeature from "./CreateFeature";
const UpdateFeature = () => {
  const { data, isLoading } = useGetSponsorshipFeatures();
  const { mutate: updateFeature } = useUpdateFeature();
  const { mutate: deleteFeature } = useDeleteSponsorFeature();

  const [featureIndex, setFeatureIndex] = useState("");
  const [features, setFeatures] = useState<any>([]);

  const methods = useForm({
    resolver: yupResolver(yup.object({})),
  });
  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
  } = methods;

  useMemo(() => {
    const features = data?.map((feature: any) => ({
      value: feature.id.toString(),
      label: feature.name,
    }));
    setFeatures(features);
    setFeatureIndex(features?.[0]?.value);
  }, [data, setFeatures]);

  useMemo(() => {
    const name = data?.filter((d: any) => d.id === +featureIndex);
    setValue("name", name?.[0]?.name);
  }, [data, featureIndex, setValue]);

  const onChangeFeature = (data: any) => setFeatureIndex(data);

  const onSubmit = ({ name }: any) => {
    updateFeature(
      {
        featureIndex,
        name,
      },
      {
        onSuccess: () => {
          showNotification({
            title: "Success",
            message: "Successfully updated",
          });
        },
      }
    );
  };

  const handleDeleteSponsorship = () =>
    deleteFeature(
      { featureIndex },
      {
        onSuccess: () => {
          showNotification({
            title: "Success",
            message: "Successfully deleted",
          });
          setFeatureIndex(data?.[0].value.toString());
        },
      }
    );

  return (
    <Box w="70%" mx="auto" my={20}>
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          minWidth: "80%",
        }}
      >
        {!isLoading && data && (
          <Controller
            name="features"
            control={control}
            render={({ field }) => (
              <Select
                w="100%"
                mx="auto"
                label="Select feature"
                {...field}
                onChange={onChangeFeature}
                defaultValue={featureIndex}
                data={features ?? []}
                error={errors?.sponsorships?.message as string}
              />
            )}
          />
        )}
        <Button
          rightIcon={<IconTrash size="1.2rem" />}
          onClick={handleDeleteSponsorship}
          color="red"
          variant="light"
          type="submit"
          mt={20}
          ml={10}
        >
          Delete
        </Button>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            alignItems: errors.name?.message ? "center" : "end",
          }}
          mt={20}
        >
          <TextInput
            w="100%"
            label="Feature name"
            placeholder="Feature name"
            {...register("name")}
          />

          <Button
            rightIcon={<IconPencil size="1.2rem" />}
            type="submit"
            variant="light"
            ml={10}
          >
            Update
          </Button>
        </Box>
      </form>
      <CreateFeature />
    </Box>
  );
};

export default UpdateFeature;
