import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Button, Text } from "@mantine/core";
import EventContext from "context/EventContext";
import HotelContext from "context/HotelContext";
import { useGetAllEvents } from "services/enteties/events/useGetAllEvents";
import PageWrapper from "components/core/PageWrapper";
import CoreTable from "components/core/CoreTable";
import CoreModal from "components/core/CoreModal";
import { useDeleteEvent } from "services/enteties/events/useDeleteEvent";
import { useForm, FormProvider } from "react-hook-form";
import EventDetailsForm from "components/forms/events/EventDetailsForm";
import { useCreateEvent } from "services/enteties/events/useCreateEvent";
import { showNotification } from "@mantine/notifications";
import { useDuplicateEvent } from "services/enteties/events/useDuplicateEvent";
import { getIds } from "services/utils/getIds";

const Events = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [deleteId, setDeleteId] = useState<any>("");

  const { mutate: deleteEvent } = useDeleteEvent();
  const { mutate: duplicateEvent } = useDuplicateEvent();
  const { mutate: createEvent } = useCreateEvent();
  const [opened, setOpened] = useState(false);

  const handlePagination = (page: number) => setPage(page);

  const { mainHotel, alternateHotels, resetContextState } =
    useContext(HotelContext);
  const { startDate, endDate, resetEventContext } = useContext(EventContext);

  const { data, isLoading, isFetching } = useGetAllEvents(page);
  const methods = useForm({
    // resolver: yupResolver(yup.object().shape(hotelValidation())),
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    resetContextState();
    reset();
    resetEventContext();
  }, []);

  const headerColumns = [
    { id: 1, title: "id" },
    { id: 2, title: "Name" },
    { id: 3, title: "Slug" },
    { id: 4, title: "Created at" },
    { id: 5, title: "Duplicate" },
    { id: 6, title: "Actions" },
  ];
  const rows = data?.data?.map((event: any, key: number) => (
    <tr key={key}>
      <td>{event.id}</td>
      <td>{event.name}</td>
      <td>{event.slug}</td>
      <td>{new Date(event.created_at).toLocaleDateString()}</td>
      <td>
        <Button
          onClick={() =>
            duplicateEvent(event.id, {
              onSuccess: () => {
                showNotification({
                  title: "Success",
                  message: "Successfully duplicated",
                });
              },
              onError: (error: any) => {
                showNotification({
                  title: "Error",
                  message: error.response.data.message,
                });
              },
            })
          }
          variant="subtle"
          color="blue"
          size="xs"
        >
          Duplicate
        </Button>
      </td>
      <td>
        <Button
          onClick={() => navigate(`/events/${event.id}`)}
          variant="subtle"
          size="xs"
        >
          Edit
        </Button>
        <Button
          onClick={() => setDeleteId(event.id)}
          variant="subtle"
          color="red"
          size="xs"
        >
          Delete
        </Button>
      </td>
    </tr>
  ));

  const onSubmit = (data: any) => {
    createEvent(
      {
        ...data,
        hotel_id: getIds(mainHotel)[0],
        alternate_hotel_ids: getIds(alternateHotels),
        start_date: startDate,
        end_date: endDate,
        is_current_upcoming_event:
          data.is_current_upcoming_event === "yes" ? 1 : 0,
        is_next_event: data.is_next_event === "yes" ? 1 : 0,
      },
      {
        onSuccess: () => {
          showNotification({
            title: "Success",
            message: "Successfully updated",
          });
          resetEventContext();
          resetContextState();
          setOpened(false);
          reset();
        },
        onError: (error: any) => {
          showNotification({
            title: "Error",
            message: error.response.data.message,
          });
        },
      }
    );
  };

  return (
    <PageWrapper
      title="Events"
      action={<Button onClick={() => setOpened(true)}>Add New Event</Button>}
    >
      <CoreModal
        opened={!!deleteId}
        onClose={() => setDeleteId("")}
        title={"Delete Event"}
      >
        <Text ta="center" fw={500} my={20}>
          Are you sure you want to delete this event?
        </Text>
        <Flex justify="space-evenly">
          <Button onClick={() => setDeleteId("")}>Cancel</Button>
          <Button
            onClick={() => {
              deleteEvent(deleteId, {
                onSuccess: () => {
                  showNotification({
                    title: "Success",
                    message: "Successfully deleted",
                  });
                },
                onError: (error: any) => {
                  showNotification({
                    title: "Error",
                    message: error.response.data.message,
                  });
                },
              });
              setDeleteId("");
            }}
          >
            Confirm
          </Button>
        </Flex>
      </CoreModal>

      <CoreModal
        opened={opened}
        onClose={() => setOpened(false)}
        title={"Create New Event"}
        size={850}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <EventDetailsForm />
          </FormProvider>
          <Button mx="auto" type="submit" mt={20}>
            Create Event
          </Button>
        </form>
      </CoreModal>
      <CoreTable
        rows={rows}
        page={page}
        headerColumns={headerColumns}
        perPage={data?.meta?.per_page}
        totalResults={data?.meta?.total}
        from={data?.meta?.from}
        to={data?.meta?.to}
        lastPage={data?.meta?.last_page}
        loading={isLoading}
        fetching={isFetching}
        handlePagination={handlePagination}
      />
    </PageWrapper>
  );
};

export default Events;
