import { Grid, TextInput, Textarea } from "@mantine/core";
import { useFormContext } from "react-hook-form";
import ImageUpload from "components/core/ImageUpload/ImageUpload";
interface IFCompanyDetailsForm {
  currentPhoto: string;
}

const CompanyDetailsForm = ({ currentPhoto }: IFCompanyDetailsForm) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const companyDetailsInputs = [
    {
      input: (
        <ImageUpload
          placeholderText="Select Company logo"
          currentPhoto={currentPhoto}
          name="company-logo"
        />
      ),
      span: {
        lg: 2,
        md: 7,
      },
    },
    {
      input: (
        <Grid>
          <Grid.Col lg={6}>
            <TextInput
              withAsterisk
              placeholder="Company name"
              {...register("company_name")}
              label="Company name"
              error={errors.company_name?.message as string}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              withAsterisk
              {...register("company_website")}
              placeholder="Company website"
              label="Company website"
              error={errors.company_website?.message as string}
            />
          </Grid.Col>
          <Grid.Col>
            <Textarea
              withAsterisk
              {...register("company_description")}
              placeholder="Company description"
              label="Company description"
              minRows={4}
              maxRows={8}
              error={errors.company_description?.message as string}
            />
          </Grid.Col>
        </Grid>
      ),
      span: {
        lg: 8,
        md: 12,
      },
    },
  ];

  return (
    <Grid justify="space-around" p={10}>
      {companyDetailsInputs.map((input: any, key: number) => (
        <Grid.Col md={input.span.md} lg={input.span.lg} key={key}>
          {input.input}
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default CompanyDetailsForm;
