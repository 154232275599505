import { useContext, useState } from "react";
import ImageUpload from "components/core/ImageUpload/ImageUpload";

import {
  Grid,
  Flex,
  Text,
  Select,
  TextInput,
  Rating,
  Divider,
} from "@mantine/core";
import { useFormContext, Controller } from "react-hook-form";
import CountriesList from "components/essentials/CountriesList";
import HotelContext from "context/HotelContext";
import { useMediaQuery } from "@mantine/hooks";
import Wysiwyg from "components/core/Wysiwyg/Wysiwyg";

interface IFHotelDetails {
  currentPhoto?: any;
  bannerPhoto?: any;
  rating?: any;
  createNew?: boolean;
}
const HotelDetailsForm = ({ currentPhoto, bannerPhoto, createNew }: IFHotelDetails) => {
  const data: any = [
    { value: "regular", label: "Regular" },
    { value: "venue", label: "Venue" },
  ];

  const isMdScreen = useMediaQuery('(min-width: 768px');

  const {
    register,
    formState: { errors },

    control,
  } = useFormContext();

  const { rating, handleSetRating } = useContext(HotelContext);

  const updateHotelForm = [
    {
      input: (
        <TextInput
          withAsterisk
          label="Name"
          {...register("name")}
          placeholder="Name"
          error={errors.name?.message as string}
        />
      ),
      span: { md: 6, lg: 6 },
    },
    {
      input: (
        <TextInput
          withAsterisk
          label="Address"
          {...register("address")}
          placeholder="Address"
          error={errors.address?.message as string}
        />
      ),
      span: { md: 6, lg: 6 },
    },
    {
      input: <CountriesList />,
      span: { md: 6, lg: 6 },
    },
    {
      input: (
        <TextInput
          withAsterisk
          label="City"
          {...register("city")}
          placeholder="City"
          error={errors.city?.message as string}
        />
      ),
      span: { md: 6, lg: 6 },
    },
    {
      input: (
        <TextInput
          label="Action Url"
          {...register("action_url")}
          placeholder="Action Url"
          error={errors.action_url?.message as string}
        />
      ),
      span: { md: 8, lg: createNew ? 6 : 12 },
    },

    {
      input: (
        <Wysiwyg 
          name="description"
          control={control}
          label="Description"
        />
      ),
      span: { md: 6, lg: 12 },
    },
  ];

  createNew &&
    updateHotelForm.unshift({
      input: (
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select
              withAsterisk
              {...field}
              label="Type"
              placeholder="Select type"
              data={data ?? []}
              error={errors?.type?.message as string}
            />
          )}
        />
      ),
      span: { md: 6, lg: 6 },
    });

  return (
    <>
      <Grid justify="space-around" align="flex-start" p={10}>
        <Grid.Col md={6} lg={4}>
          <Flex direction={isMdScreen ? 'row' : 'column'} align="center" gap={isMdScreen ? 10 : 60}>
            <ImageUpload
              placeholderText="Select main photo"
              currentPhoto={currentPhoto}
              name="hero_image"
              deleteText="Delete main photo"
            />
            <Divider size={1} orientation={'vertical'} hidden={!isMdScreen}/>
            <div>
              <ImageUpload
                placeholderText="Select banner photo"
                additionalInfo="16:9 aspect ratio"
                currentPhoto={bannerPhoto}
                name="banner_image"
                deleteText="Delete banner"
                />
            </div>
          </Flex>
        </Grid.Col>
        <Grid.Col md={12} lg={6} mt={{ base: 50, md: 50, lg: 0 }}>
          <Flex mb={20} align="center">
            <Text mb={5} mr={10}>
              Hotel rating
            </Text>
            <Rating value={rating} onChange={handleSetRating} />
          </Flex>
          <Grid>
            {updateHotelForm.map((input: any, key: number) => (
              <Grid.Col md={input.span.md} lg={input.span.lg} key={key}>
                {input.input}
              </Grid.Col>
            ))}
          </Grid>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default HotelDetailsForm;
