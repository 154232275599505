import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_URL,
  withCredentials: true,
});

http.defaults.headers.post["Content-Type"] = `multipart/form-data`;
http.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    return config;
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (config: AxiosResponse): AxiosResponse {
    return config;
  },
  function (error: AxiosError): Promise<AxiosError> {
    if (error?.response?.status === 401) {
      document.dispatchEvent(new Event("logout-event"));
    }
    console.error(`[response error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
  }
);
export default http;
