import { List, ThemeIcon } from "@mantine/core";
import { IconSpeakerphone } from "@tabler/icons";
import { Link } from "react-router-dom";
const PreviousSpeakingExperiences = ({ data }: any) => {
  return (
    <List
      spacing="xs"
      size="sm"
      mx={20}
      center
      icon={
        <ThemeIcon color="teal" size={24} radius="xl">
          <IconSpeakerphone size="1rem" />
        </ThemeIcon>
      }
    >
      { data?.length && data?.map((experience: string, key: number) => (
        <List.Item my={20} key={key}>
          <Link to={experience} target="_blank" rel="noopener noreferrer">
            {experience}
          </Link>
        </List.Item>
      ))}
    </List>
  );
};

export default PreviousSpeakingExperiences;
