import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetSponsorshipById = (sponsorId: string | undefined) => {
  return useQuery(
    ["sponsorships-details", sponsorId],
    () => http.get(`/api/sponsorships/${sponsorId}`),
    {
      enabled: !!sponsorId,
      select(data) {
        return data.data;
      },
    }
  );
};
