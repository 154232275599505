import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateHighlight = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (reqData: any) =>{
      const { id, ...data } = reqData;
      return  http.post(`api/speakers/${id}/highlight`, { _method: "PATCH", ...data })
    },
      onSuccess: () => {
      queryClient.invalidateQueries(["speakers"]);
    },
  });
};
