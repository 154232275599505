import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateFeature = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => http.post(`/api/sponsorships-features`, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["sponsorships-features"]);
    },
  });
};
