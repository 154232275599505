import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import FileUploadContext from "context/FileUploadContext";
import { useGetAllFiles } from "services/enteties/files/useGetFiles";
import CoreTable from "components/core/CoreTable";
import { Flex, Button, Text } from "@mantine/core";
import PageWrapper from "components/core/PageWrapper";
import CoreModal from "components/core/CoreModal";
import { useDeleteFile } from "services/enteties/utilities/useDeleteFile";

import { useUploadFiles } from "services/enteties/files/useUploadFiles";
import { showNotification } from "@mantine/notifications";
import FileUpload from "components/core/ImageUpload/FileUpload";
const FileManager = () => {
	const { data, isFetching, isLoading } = useGetAllFiles();
	const { fileState, cleanupContextState, setFileState } =
		useContext(FileUploadContext);
	const [page, setPage] = useState(1);
	const handlePagination = (page: number) => setPage(page);
	const { mutate: deleteFile } = useDeleteFile("files");
	const { mutate: uploadFiles } = useUploadFiles();

	const [deleteId, setDeleteId] = useState<any>("");
	const [opened, setOpened] = useState(false);

	const headerColumns = [
		{ id: 1, title: "id" },
		{ id: 2, title: "Name" },
		{ id: 3, title: "Path" },
		{ id: 4, title: "Actions" },
	];

	const rows = data?.data?.map((file: any, key: number) => (
		<tr key={key}>
			<td>{file.id}</td>
			<td>{file.original_name}</td>
			<td>
				<Link to={file.path} target="_blank" rel="noopener noreferrer">
					{file.path}
				</Link>
			</td>
			<td>
				<Button
					onClick={() => setDeleteId(file.id)}
					variant="subtle"
					color="red"
					size="xs"
				>
					Delete
				</Button>
			</td>
		</tr>
	));

	return (
		<PageWrapper
			title="File Manager"
			action={
				<Button onClick={() => setOpened(true)}>Import New File</Button>
			}
		>
			<CoreModal
				opened={!!deleteId}
				onClose={() => setDeleteId("")}
				title={"Delete File"}
			>
				<Text ta="center" fw={500} my={20}>
					Are you sure you want to delete this file?
				</Text>
				<Flex justify="space-evenly">
					<Button onClick={() => setDeleteId("")}>Cancel</Button>
					<Button
						onClick={() => {
							deleteFile(deleteId);
							setDeleteId("");
						}}
					>
						Confirm
					</Button>
				</Flex>
			</CoreModal>
			<CoreModal
				opened={opened}
				onClose={() => {
					setOpened(false);
					setFileState([]);
				}}
				title={"File manager import"}
			>
				<FileUpload placeholderText="Click to select" maxFiles={5} />
				<Flex justify="space-evenly" my={10}>
					<Button
						onClick={() => {
							setOpened(false);
							setFileState([]);
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							uploadFiles(
								{ files: fileState },
								{
									onSuccess: () => {
										showNotification({
											title: "Success",
											message: "Successfully updated",
										});
										cleanupContextState();
									},
									onError: (error: any) => {
										showNotification({
											title: "Error",
											message:
												error.response.data.message,
										});
									},
								}
							);

							setOpened(false);
						}}
					>
						Confirm
					</Button>
				</Flex>
			</CoreModal>
			<CoreTable
				rows={rows}
				page={page}
				headerColumns={headerColumns}
				perPage={data?.meta?.per_page}
				totalResults={data?.meta?.total}
				from={data?.meta?.from}
				to={data?.meta?.to}
				lastPage={data?.meta?.last_page}
				loading={isLoading}
				fetching={isFetching}
				handlePagination={handlePagination}
			/>
		</PageWrapper>
	);
};

export default FileManager;
