import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetSponsorshipFeatures = () => {
  return useQuery(
    ["sponsorships-features"],
    () => http.get(`/api/sponsorships-features`),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      select(data) {
        return data.data;
      },
    }
  );
};
