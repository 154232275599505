import http from "services/http";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { TicketData } from "types/ticket";

export const useGetTicket = (
	ticketId: string | undefined
): UseQueryResult<TicketData> => {
	return useQuery(
		["tickets", ticketId],
		() => http.get(`/api/tickets/${ticketId}`),
		{
			select(data) {
				return data.data;
			},
		}
	);
};
