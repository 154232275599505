import { Select } from "@mantine/core";
import { useGetCountries } from "services/enteties/utilities";
import { useFormContext, Controller } from "react-hook-form";
const CountriesList = () => {
  const { data } = useGetCountries();
  const {
    getValues,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name="country"
      control={control}
      render={({ field }) => (
        <Select
          withAsterisk
          {...field}
          searchable
          label="Countries"
          placeholder="Select country"
          defaultValue={getValues("country")}
          data={data ?? []}
          error={errors?.country?.message as string}
        />
      )}
    />
  );
};

export default CountriesList;
