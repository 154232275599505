import { Box, Text, Image, createStyles } from "@mantine/core";
import { useState, useContext } from "react";
import FileUploadContext from "context/FileUploadContext";
import { Dropzone } from "@mantine/dropzone";
import { IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useMemo } from "react";
interface IFImageUpload {
  placeholderText: string;
  additionalInfo?: string;
  currentPhoto?: string;
  name: string | undefined;
  deleteText?: string;
}

const useStyles = createStyles((theme) => ({
  imageWrapper: {
    minHeight: 140,
    width: 200,
    display: "flex",
    flexDirection: "column",
    align: "center",
    margin: "auto",
  },
  link: {
    marginTop: 10,
    textDecoration: "underline",
    color: theme.colors.blue,
    cursor: "pointer",
    textAlign: "center",
  },
}));

const ImageUpload = ({
  placeholderText,
  additionalInfo,
  currentPhoto,
  name,
  deleteText,
}: IFImageUpload) => {
  const [changeCurrent, setChangeCurrent] = useState<boolean>(false);
  const { imageState, setImageState } = useContext(FileUploadContext);
  const matchingImage = imageState?.filter((img: any) => img?.name === name);
  const { classes } = useStyles();

  const photoPreviews = () => {
    if (!changeCurrent && matchingImage.length !== 0) {
      const imageUrl = URL.createObjectURL(matchingImage[0]?.file?.[0]);
      return (
        <Box className={classes.imageWrapper}>
          <Image
            maw={240}
            fit="contain"
            src={imageUrl}
            imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
          />
          <Text
            className={classes.link}
            onClick={() => {
              setImageState(
                imageState?.filter((image: any) => image !== matchingImage[0])
              );
              setChangeCurrent(false);
            }}
          >
            {deleteText ?? 'Delete Image'}
          </Text>
        </Box>
      );
    }
    if (changeCurrent && currentPhoto) {
      return (
        <Box className={classes.imageWrapper}>
          <Image maw={240} fit="contain" src={currentPhoto} />
          <Text
            className={classes.link}
            onClick={() => {
              setChangeCurrent(false);
            }}
          >
            Change image
          </Text>
        </Box>
      );
    }
  };
  useMemo(() => {
    if (currentPhoto) setChangeCurrent(!!currentPhoto);
  }, [currentPhoto]);

  return (
    <>
      {!changeCurrent && !matchingImage.length ? (
        <Box sx={{ height: 140, width: 200, margin: "auto" }}>
          <Dropzone
            maxFiles={1}
            accept={IMAGE_MIME_TYPE}
            style={{ height: 140, width: 200 }}
            onDrop={(file: any) => {
              setImageState([...imageState, { name: name, file: file }]);
              setChangeCurrent(false);
            }}
          >
            <Text>{placeholderText}</Text>
            <Text fz="sm" color="dimmed" ta="center">
              or drag over
            </Text>
            {additionalInfo && <Text fz="xs" color="dimmed" ta="center" mt={20}>{additionalInfo}</Text>}
          </Dropzone>
          <Text
            onClick={() => setChangeCurrent(currentPhoto ? true : false)}
            className={classes.link}
          >
            Cancel
          </Text>
        </Box>
      ) : (
        photoPreviews()
      )}
    </>
  );
};

export default ImageUpload;
