import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateTicket = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data: any) => http.post(`/api/tickets`, data),
		onSuccess: () => {
			queryClient.invalidateQueries(["tickets"]);
		},
	});
};
