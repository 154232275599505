import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteImage = (queryKey: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: any) => http.delete(`/api/images/${id}/delete`),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
    },
  });
};
