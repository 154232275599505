import { useState, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import PageWrapper from "components/core/PageWrapper";
import { Accordion, Text, Button, Skeleton } from "@mantine/core";
import { useForm, FormProvider } from "react-hook-form";
import EventDetailsForm from "components/forms/events/EventDetailsForm";
import { useGetEvent } from "services/enteties/events/useGetEvent";
import HotelContext from "context/HotelContext";
import { useGetHotel } from "services/enteties/hotels";
import EventContext from "context/EventContext";
import { useUpdateEvent } from "services/enteties/events/useUpdateEvent";
import { showNotification } from "@mantine/notifications";
import { getIds } from "services/utils/getIds";
const Event = () => {
  const { id } = useParams();
  const methods = useForm({});
  const [value, setAccordion] = useState<string[]>(["item-1"]);
  const { handleSubmit, setValue } = methods;
  const { handleMainHotel, handleAlternateHotels, alternateHotels, mainHotel } =
    useContext(HotelContext);

  const {
    handleSetEndDate,
    handleSetStartDate,
    handleNextEvent,
    handleUpcoming,
    startDate,
    endDate,
    nextEvent,
    upcoming,
  } = useContext(EventContext);

  const { mutate } = useUpdateEvent(id);

  const { data, isLoading } = useGetEvent(id);
  const { data: hotel } = useGetHotel(data?.hotel?.id);
  useMemo(() => {
    const defaultValues = {
      name: data?.name ?? "",
      slug: data?.slug ?? "",
      whova_event_id: data?.whova_event_id ?? "",
    };
    Object.keys(defaultValues).forEach((value: any) => {
      setValue(value, data?.[value]);
    });
    handleAlternateHotels(
      data
        ? data.alternate_hotels.map((hotel: any) => ({
            value: hotel?.id,
            label: hotel?.name,
          }))
        : []
    );
    handleMainHotel(data ? [{ value: hotel?.id, label: hotel?.name }] : []);
    handleNextEvent(data?.is_next_event ? "1" : "0");
    handleUpcoming(data?.is_current_upcoming_event ? "1" : "0");
    handleSetStartDate(data?.start_date);
    handleSetEndDate(data?.end_date);
  }, [setValue, data, hotel]);

  const onSubmit = (data: any) => {
    mutate(
      {
        ...data,
        is_next_event: nextEvent,
        is_current_upcoming_event: upcoming,
        hotel_id: getIds(mainHotel).toString(),
        alternate_hotel_ids: getIds(alternateHotels),
        start_date: startDate,
        end_date: endDate,
      },
      {
        onSuccess: () => {
          showNotification({
            title: "Success",
            message: "Successfully updated",
          });
        },
        onError: (error: any) =>
          showNotification({
            title: "Error",
            message: error.response.data.message,
          }),
      }
    );
  };

  return (
    <PageWrapper title="Event" navigateBack>
      {isLoading ? (
        <Skeleton height={250} />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Accordion
              radius="md"
              variant="separated"
              multiple
              value={value}
              onChange={setAccordion}
            >
              <Accordion.Item value="item-1">
                <Accordion.Control>
                  <Text fw={500}>Edit Event</Text>
                </Accordion.Control>
                <Accordion.Panel p="md">
                  <EventDetailsForm />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
            <Button type="submit" mt={20}>
              Update event
            </Button>
          </form>
        </FormProvider>
      )}
    </PageWrapper>
  );
};

export default Event;
