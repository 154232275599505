import { useContext } from "react";
import { Link } from "react-router-dom";
import FileUploadContext from "context/FileUploadContext";
import { Box, ActionIcon, Card, Text, createStyles } from "@mantine/core";
import { Dropzone, PDF_MIME_TYPE } from "@mantine/dropzone";
import { IconX, IconFile } from "@tabler/icons";
import { useDeleteFile } from "services/enteties/utilities";

const useStyles = createStyles((theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
    boxShadow: theme.shadows.md,
    width: 300,
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: 20,
    backgroundColor: theme.colors.blue[1],
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  text: {
    overflow: "hidden",
    paddingRight: "50px",
    paddingLeft: "10px",
  },
}));
const FileUpload = ({ placeholderText, currentFiles, maxFiles }: any) => {
  const {
    fileState,
    deleteFile: deleteCurrent,
    setFileState,
  } = useContext(FileUploadContext);

  const { mutate: deleteFile } = useDeleteFile("hotel");
  const { classes } = useStyles();
  const currentFilesStatus = currentFiles && Object.keys(currentFiles).length;

  const myComponent = ({ id, original_name, path, deleteAction }: any) => (
    <Box my={20} key={id} className={classes.card}>
      <Box className={classes.icon}>
        <IconFile size={25} />
      </Box>
      <Link
        className={classes.text}
        to={path}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text>{original_name}</Text>
      </Link>
      <ActionIcon onClick={() => deleteAction(id)} mx={10}>
        <IconX size={15} />
      </ActionIcon>
    </Box>
  );

  const existingFilesDisplay = () =>
    myComponent({
      id: currentFiles.id,
      original_name: currentFiles.original_name,
      path: currentFiles.path,
      deleteAction: deleteFile,
    });

  const newFilesDisplay = () =>
    fileState.map((file: any, key: number) =>
      myComponent({
        id: key,
        original_name: file.name,
        path: file.path,
        deleteAction: deleteCurrent,
      })
    );

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100%",
      }}
    >
      <Box miw="100%">
        <Dropzone
          maxFiles={maxFiles}
          accept={PDF_MIME_TYPE}
          onDrop={setFileState}
          disabled={
            currentFilesStatus || fileState.length >= maxFiles ? true : false
          }
        >
          <Text ta="center">{placeholderText}</Text>
          <Text ta="center" fz="sm" color="dimmed">
            or drag over
          </Text>
        </Dropzone>
      </Box>

      {currentFilesStatus ? existingFilesDisplay() : newFilesDisplay()}
    </Card>
  );
};

export default FileUpload;
