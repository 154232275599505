import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { MantineProvider } from "@mantine/core";
import defaultTheme from "./theme";
import { Notifications } from "@mantine/notifications";
import { AuthContextProvider } from "./context/AuthContext";
import { FileUploadContextProvider } from "context/FileUploadContext";
import { HotelContextProvider } from "context/HotelContext";
import { ModalsProvider } from "@mantine/modals";
import { EventContextProvider } from "context/EventContext";
import { SponsorshipContextProvider } from "context/SponsorshipContext";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <BrowserRouter>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={defaultTheme}>
        <ModalsProvider>
          <Notifications position="top-right" limit={1} />
          <AuthContextProvider>
            <FileUploadContextProvider>
              <EventContextProvider>
                <HotelContextProvider>
                  <SponsorshipContextProvider>
                    <App />
                  </SponsorshipContextProvider>
                </HotelContextProvider>
              </EventContextProvider>
            </FileUploadContextProvider>
          </AuthContextProvider>
        </ModalsProvider>
      </MantineProvider>
    </BrowserRouter>
  </>
);
