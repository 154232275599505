import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetHotel = (hotelId: string | undefined) => {
  return useQuery(
    ["hotel", hotelId],
    () => http.get(`/api/hotels/${hotelId}`),
    {
      enabled: !!hotelId,
      select(data) {
        return data.data;
      },
    }
  );
};
