import http from "services/http";
import { useQuery } from "@tanstack/react-query";

export const useGetTimeZones = () => {
  return useQuery(["time-zones"], () => http.get(`api/timezones`), {
    select(data) {
      return data.data;
    },
    refetchOnWindowFocus: false,
  });
};
