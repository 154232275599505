import { useContext } from "react";
import { Box, Avatar, Text, Menu, createStyles } from "@mantine/core";
import { useLogout } from "services/enteties/auth";
import { Link } from "react-router-dom";
import AuthContext from "context/AuthContext";
import { IFNavigationLink } from "types/navigation";
import { IconLogout, IconUser, IconServer, IconSettings } from "@tabler/icons";
const useStyles = createStyles((theme) => ({
  menu: {
    cursor: "pointer",
  },
  profile: { display: "flex", alignItems: "center" },
  profileData: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  link: {
    display: "flex",
    alignItems: "start",
    padding: 2,
    lineHeight: 1,
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[7],
    fontWeight: 500,
    cursor: "pointer",
  },
}));
const ProfileWidget = () => {
  const { classes } = useStyles();
  const { mutate: logout } = useLogout();
  const { user } = useContext(AuthContext);

  const links = [
    // { title: "Site Options", ref: "", icon: <IconSettings size={17} /> },
    // { title: "Log", ref: "", icon: <IconServer size={17} /> },
    // { title: "My Profile", ref: "", icon: <IconUser size={17} /> },
    { title: "Log out", ref: "", icon: <IconLogout size={17} /> },
  ];

  return (
    <Box className={classes.menu}>
      <Menu trigger="hover" openDelay={100} closeDelay={400} withArrow>
        <Menu.Target>
          <Box className={classes.profile}>
            <Avatar radius="xl" mx={10} size={40} color="green" />
            <Box className={classes.profileData}>
              <Text fz="md" fw={400}>
                {user?.name}
              </Text>
              <Text color="dimmed" fz="sm">
                {user?.email}
              </Text>
            </Box>
          </Box>
        </Menu.Target>
        <Menu.Dropdown>
          {links.map((link: IFNavigationLink, key: number) =>
            link.title !== "Log out" ? (
              <Box key={`${link.title}-${key}`}>
                {key !== 0 && <Menu.Divider />}
                <Menu.Item>
                  <Link to={link.ref} className={classes.link}>
                    {link.title}
                    <Box ml={10}>{link.icon}</Box>
                  </Link>
                </Menu.Item>
              </Box>
            ) : (
              <Box key={`${link.title}-${key}`}>
                {/* <Menu.Divider /> */}
                <Menu.Item>
                  <Box className={classes.link} onClick={() => logout()}>
                    {link.title}
                    <Box ml={10}>{link.icon}</Box>
                  </Box>
                </Menu.Item>
              </Box>
            )
          )}
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};

export default ProfileWidget;
