import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateSponsorship = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }: any) =>
      http.post(`/api/sponsorships/${id}`, { _method: "PATCH", ...data }),
    onSuccess: () => {
      queryClient.invalidateQueries(["sponsorships-details"]);
      queryClient.invalidateQueries(["sponsorships-by-event"]);
      queryClient.invalidateQueries(["sponsorships-main"]);
      queryClient.invalidateQueries(["sponsorships-base"]);
    },
  });
};
