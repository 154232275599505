import { useContext } from "react";
import http from "services/http";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import AuthContext from "context/AuthContext";

export const useLogout = () => {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async () => {
      await http.get("/sanctum/csrf-cookie");
      return await http.post("/logout");
    },
    onSuccess: () => {
      navigate("/login");
      setAuth(false);
    },
  });
};
