import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetSponsorshipBase = (id: string | undefined, page: number) => {
	let queryParams = `page=${page}`;
	if (id) {
		queryParams += `&filter[event_id]=${id}`;
	}
	queryParams += "&filter[base]=true&include=features";

	return useQuery(
		["sponsorships-base", page],
		() => {
			if (id === undefined) return Promise.resolve(null);
			return http.get(`/api/sponsorships?${queryParams}`);
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			select(data) {
				return data?.data ?? null;
			},
		}
	);
};
