import { useEffect, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Speakers from "./pages/speakers/Speakers";
import Speaker from "./pages/speakers/Speaker";
import { useNavigate } from "react-router-dom";
import AuthContext from "./context/AuthContext";
import PageGuard from "./components/core/PageGuard";
import Hotels from "pages/hotels/Hotels";
import Hotel from "pages/hotels/Hotel";
import FileManager from "pages/FileManager";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
import Events from "pages/events/Events";
import Event from "pages/events/Event";
import Sponsorship from "pages/sponsorships/Sponsorship";
import Tickets from "pages/tickets/Tickets";
import Ticket from "pages/tickets/Ticket";
import Participants from "pages/participants/Participants";
import SelectEvent from "pages/sponsorships/SelectEvent";
import Email from "pages/Email";
import Newsletter from "pages/Newsletter";

function App() {
	const navigate = useNavigate();
	const { setAuth } = useContext(AuthContext);
	const queryClient = new QueryClient({
		defaultOptions: { queries: { staleTime: 5 } },
	});
	useEffect(() => {
		document.addEventListener("logout-event", () => {
			navigate("/login");
			setAuth(false);
		});
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route element={<PageGuard />}>
					<Route path="/admin" element={<Admin />} />
					<Route path="/speakers" element={<Speakers />} />
					<Route path="/speakers/:id" element={<Speaker />} />
					<Route path="/hotels" element={<Hotels />} />
					<Route path="/hotels/:id" element={<Hotel />} />
					<Route path="/file-manager" element={<FileManager />} />
					<Route path="/events" element={<Events />} />
					<Route path="/events/:id" element={<Event />} />
					<Route path="/sponsorships" element={<SelectEvent />} />
					<Route path="/sponsorships/:id" element={<Sponsorship />} />
					<Route path="/tickets" element={<Tickets />} />
					<Route path="/tickets/:id" element={<Ticket />} />
					<Route path="/participants" element={<Participants />} />
					<Route path="/email-logs" element={<Email />} />
					<Route path="/newsletter" element={<Newsletter />} />
					<Route path="*" element={<Admin />} />
				</Route>
				<Route path="*" element={<Login />} />
			</Routes>
		</QueryClientProvider>
	);
}

export default App;
