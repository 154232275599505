import { Box, Grid, TextInput, Textarea } from "@mantine/core";
import SpeechTypeList from "components/essentials/SpeechTypeList";
import { useFormContext, Controller } from "react-hook-form";
import TimeZones from "components/essentials/TimeZonesList";
import { DateInput } from "@mantine/dates";
const PresentationDetailsForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const presentationDetailsForm = [
    {
      input: (
        <Grid>
          <Grid.Col>
            <Controller
              name="created_at"
              control={control}
              render={({ field }) => (
                <DateInput
                  withAsterisk
                  {...field}
                  value={new Date(field.value)}
                  label="Date input"
                  placeholder="Date input"
                />
              )}
            />
          </Grid.Col>
          <Grid.Col>
            <SpeechTypeList />
          </Grid.Col>
          <Grid.Col>
            <TimeZones />
          </Grid.Col>
        </Grid>
      ),
      span: { lg: 5, md: 12 },
    },
    {
      input: (
        <Grid p={0}>
          <Grid.Col>
            <TextInput
              withAsterisk
              placeholder="Panel name"
              {...register("panel_name")}
              label="Panel name"
              error={errors.companyName?.message as string}
            />
          </Grid.Col>
          <Grid.Col pb={0}>
            <Textarea
              withAsterisk
              {...register("content_of_presentations")}
              placeholder="Content of presentation"
              label="Content of presentation"
              minRows={4}
              maxRows={9}
              error={errors.companyDescription?.message as string}
            />
          </Grid.Col>
        </Grid>
      ),
      span: {
        lg: 5,
        md: 12,
      },
    },
  ];
  return (
    <Grid justify="space-around">
      {presentationDetailsForm.map((input: any, key: number) => (
        <Grid.Col md={input.span.md} lg={input.span.lg} key={key}>
          {input.input}
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default PresentationDetailsForm;
