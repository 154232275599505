import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateEvent = (id: string | undefined) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) =>
      http.post(`/api/events/${id}`, { _method: "PATCH", ...data }),
    onSuccess: () => {
      queryClient.invalidateQueries(["events"]);
    },
  });
};
