import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export const useDeleteSpeaker = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userId: any) =>
      http.post(`/api/speakers/${userId}`, { _method: "DELETE" }),
    onSuccess: () => {
      queryClient.invalidateQueries(["speakers"]);
    },
  });
};
