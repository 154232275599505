import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUploadParticipantsExcelFile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      const { id, files, eventId } = data;
      const formData = new FormData();
      formData.append("id", id);
      formData.append("eventId", eventId);
      formData.append("file", files);
      //formData.append("_method", "PATCH");
      return http.post(`/api/participants/import-excel`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["files"]);
    },
  });
};