import { useState } from "react";
import {
  Box,
  createStyles,
  NavLink,
  Navbar,
  ScrollArea,
  Image,
  rem,
} from "@mantine/core";
import navLinks from "./navLinks";

import { IFNavigationLink, IFSubMenuFNavLinks } from "types/navigation";
import { useLogout } from "services/enteties/auth";
import { useNavigate } from "react-router-dom";
import { IconLogout, IconServer, IconSettings } from "@tabler/icons";
const logo = require("../../images/logo.png");

const useStyles = createStyles((theme) => ({
  navigation: {
    backgroundColor: theme.colors.customGray[0],
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
  head: { marginBottom: 20 },
  navLinks: { padding: 10 },
  link: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    padding: 10,
    color: theme.colors.gray[3],
    cursor: "pointer",
    borderRadius: theme.radius.sm,
    ":hover": {
      backgroundColor: theme.colors.gray[8],
      borderRadius: 10,
    },
  },
  subtitle: {
    color: theme.colors.gray[3],
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    ":hover": {
      backgroundColor: theme.colors.gray[8],
      borderRadius: "0px 10px 10px 0px",
    },
  },
  submenu: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.fontSizes.sm,
    padding: 10,
    fontWeight: 600,
    cursor: "pointer",
    color: theme.colors.gray[3],
    ":hover": {
      backgroundColor: theme.colors.gray[8],
      borderRadius: 10,
    },
  },
  footer: {
    padding: 10,
    marginTop: "auto",
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));
const CoreNavigation = () => {
  const [opened] = useState(false);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { mutate: logout } = useLogout();

  const links = navLinks.map((link: IFNavigationLink, key: number) =>
    !link.submenu ? (
      <NavLink
        className={classes.link}
        label={link.title}
        onClick={() => navigate(link.ref)}
        key={`${link.title}-${key}`}
        icon={link.icon}
      />
    ) : (
      <NavLink
        className={classes.link}
        label={link.title}
        key={`${link.title}-${key}`}
        icon={link.icon}
      >
        {link.submenu.map((sub: IFSubMenuFNavLinks, key: number) => (
          <NavLink
            className={classes.subtitle}
            label={sub.subtitle}
            onClick={() => navigate(link.ref)}
            key={`${sub.subtitle}-${key}`}
          />
        ))}
      </NavLink>
    )
  );
  return (
    <Navbar
      className={classes.navigation}
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ md: 250 }}
    >
      <Navbar.Section className={classes.head}>
        <Image mx="auto" width={120} src={logo} alt="logo" />
      </Navbar.Section>

      <Navbar.Section className={classes.navLinks} grow component={ScrollArea}>
        {links}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        {/* <Box className={classes.submenu} my={10}>
          <IconSettings style={{ marginRight: 10 }} size={20} />
          Site settings
        </Box>
        <Box className={classes.submenu} my={10}>
          <IconServer style={{ marginRight: 10 }} size={20} />
          Logs
        </Box> */}
        <Box className={classes.submenu} my={10} onClick={() => logout()}>
          <IconLogout style={{ marginRight: 10 }} size={20} />
          Logout
        </Box>
      </Navbar.Section>
    </Navbar>
  );
};

export default CoreNavigation;
