import { ReactNode } from "react";
import { AppShell } from "@mantine/core";
import CoreHeader from "../navigation/CoreHeader";
import CoreNavigation from "../navigation/CoreNavigation";

const CoreAppShell = ({ children }: { children: ReactNode }) => {
  return (
    <AppShell
      sx={{ overflow: "hidden" }}
      navbar={<CoreNavigation />}
      header={<CoreHeader />}
    >
      {children}
    </AppShell>
  );
};

export default CoreAppShell;
