import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetAllFiles = () => {
  return useQuery(["files"], () => http.get(`/api/files`), {
    refetchOnWindowFocus: false,
    select(data) {
      return data.data;
    },
  });
};
