import { useState, useCallback, useMemo } from "react";
import { Loader, Select, ActionIcon, Badge } from "@mantine/core";
import { IconX } from "@tabler/icons";
import { debounce } from "lodash";

const CoreMultiselect = ({
  maxLimit,
  label,
  placeholder,
  handleDebounced,
  isFetching,
  setSelected,
  selected,
  data,
}: any) => {
  const [searchValue, onSearchChange] = useState("");
  const [disabled, setDisabled] = useState(false);

  const debounced = useCallback(
    debounce((value: any) => {
      handleDebounced(value);
    }, 1000),
    []
  );

  const handleChange = (value: any) => {
    onSearchChange(value);
    debounced(value);
  };

  const receivedData = data?.map((option: any) => ({
    value: option.id,
    label: option.name,
  }));

  const handleOnChange = (selectedId: any) => {
    const checkDuplicateIncoming = receivedData.filter(
      (data: any) => data.value === selectedId
    );
    const checkDuplicateCurrent = selected.filter(
      (s: any) => s.value === selectedId
    );
    if (checkDuplicateIncoming && checkDuplicateCurrent.length === 0) {
      setSelected([...selected, ...checkDuplicateIncoming]);
    }
  };

  const filterOut = (id: any) => {
    setSelected(selected.filter((e: any) => e.value !== id));
  };

  useMemo(() => {
    selected?.length >= maxLimit ? setDisabled(true) : setDisabled(false);
  }, [selected, maxLimit]);

  return (
    <>
      <Select
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        icon={isFetching && <Loader size={18} />}
        value={selected}
        onChange={handleOnChange}
        data={receivedData ?? []}
        searchable
        searchValue={searchValue}
        onSearchChange={handleChange}
      />
      {selected?.map((s: any) => (
        <Badge
          p={10}
          m={10}
          variant="gradient"
          gradient={{ from: "indigo", to: "cyan" }}
          rightSection={
            <ActionIcon
              size="xs"
              c="white"
              radius="xl"
              variant="transparent"
              onClick={() => filterOut(s.value)}
            >
              <IconX size={25} />
            </ActionIcon>
          }
        >
          {s?.label}
        </Badge>
      ))}
    </>
  );
};

export default CoreMultiselect;
