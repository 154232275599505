import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetAllEventsContextMenu = () => {
	return useQuery(["events-all"], () => http.get(`api/events/contextmenu`), {
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		select(data) {
			return data.data;
		},
	});
};
