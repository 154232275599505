import { Select, Grid, TextInput, Loader } from "@mantine/core";
import Wysiwyg from "components/core/Wysiwyg/Wysiwyg";
import { Controller, useFormContext } from "react-hook-form";
import { useGetColorSchemes } from "services/enteties/sponsorships";


const SponsorshipDetailsForm = ({ loading }: any) => {

  const { data: colorSchemesData, isLoading: isLoadingColorSchemes} = useGetColorSchemes()

  const {
    getValues,
    control,
    formState: { errors },
    register,
  } = useFormContext();
  const data: any = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  
  let colorSchemes: any= [];
    if(colorSchemesData)
      colorSchemes = Object?.entries(colorSchemesData)?.map(([key, value]) => ({ value: key, label: value }));
 
  const detailsForm = [
    {
      input: (
        <TextInput
          icon={loading && <Loader size="sm" />}
          disabled={loading}
          label="Sponsorship name"
          placeholder="Sponsorship name"
          {...register("name")}
          error={errors.name?.message as string}
        />
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
    {
      input: (
        <Wysiwyg 
          name="description"
          control={control}
          label="Description"
          loading={loading}
        />
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
    {
      input: (
        <TextInput
          icon={loading && <Loader size="sm" />}
          disabled={loading}
          type="number"
          label="Price"
          placeholder="Price"
          {...register("price")}
          error={errors.price?.message as string}
        />
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
    {
      input: (
        <TextInput
          icon={loading && <Loader size="sm" />}
          disabled={loading}
          type="number"
          label="Amount reserved"
          placeholder="Amount reserved"
          {...register("amount_reserved")}
          error={errors.amount_reserved?.message as string}
        />
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
    {
      input: (
        <TextInput
          icon={loading && <Loader size="sm" />}
          disabled={loading}
          type="number"
          label="Quantity"
          placeholder="Quantity"
          {...register("quantity")}
          error={errors.quantity?.message as string}
        />
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
    {
      input: (
        <TextInput
          icon={loading && <Loader size="sm" />}
          disabled={loading}
          type="number"
          label="Amount sold"
          placeholder="Amount sold"
          {...register("amount_sold")}
          error={errors.amount_reserved?.message as string}
        />
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
    {
      input: (
        <TextInput
          icon={loading && <Loader size="sm" />}
          disabled={loading}
          label="Currency"
          placeholder="Currency"
          {...register("currency")}
          error={errors.currency?.message as string}
        />
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
    {
      input: (
        <Controller
          name="color_scheme"
          control={control}
          render={({ field }) => (
            <Select
              allowDeselect
              icon={loading && <Loader size="sm" />}
              disabled={loading && isLoadingColorSchemes}
              {...field}
              label="Color scheme"
              placeholder="The color scheme only affects the main packages"
              data={colorSchemes}
              error={errors?.color_scheme?.message as string}
            />
          )}
        />
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
    {
      input: (
        <Controller
          name="is_main"
          control={control}
          render={({ field }) => (
            <Select
              icon={loading && <Loader size="sm" />}
              disabled={loading}
              {...field}
              label="Main type"
              placeholder="Main type"
              data={data}
              defaultValue={getValues("is_main") ?? false}
              error={errors?.is_main?.message as string}
            />
          )}
        />
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
    {
      input: (
        <Controller
          name="is_published"
          control={control}
          render={({ field }) => (
            <Select
              icon={loading && <Loader size="sm" />}
              disabled={loading}
              {...field}
              label="Public type"
              placeholder="Public type"
              data={data}
              defaultValue={getValues("is_published") ?? false}
              error={errors?.is_published?.message as string}
            />
          )}
        />
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
  ];

  return (
    <Grid my={20}>
      {detailsForm.map((event: any, key: number) => (
        <Grid.Col md={event.span.md} lg={event.span.lg} key={key}>
          {event.input}
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default SponsorshipDetailsForm;
