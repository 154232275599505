import { useContext } from "react";
import { DateInput } from "@mantine/dates";
import { Grid, TextInput, Select } from "@mantine/core";
import { useFormContext, Controller } from "react-hook-form";
import EventContext from "context/EventContext";

import MainHotelSearch from "./MainHotelSearch";
import AlternateHotelsSearch from "./AlternateHotelsSearch";

const EventDetailsForm = () => {
  const selectOptions = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const {
    startDate,
    endDate,
    nextEvent,
    upcoming,
    handleUpcoming,
    handleNextEvent,
    handleSetStartDate,
    handleSetEndDate,
  } = useContext(EventContext);

  const eventDetailsForm = [
    {
      input: (
        <Grid>
          <Grid.Col md={6} lg={3}>
            <DateInput
              withAsterisk
              value={startDate ? new Date(`${startDate}`) : null}
              onChange={handleSetStartDate}
              label="Start event date"
              placeholder="Start event date"
            />
          </Grid.Col>
          <Grid.Col md={6} lg={3}>
            <DateInput
              withAsterisk
              value={endDate ? new Date(`${endDate}`) : null}
              onChange={handleSetEndDate}
              label="End event date"
              placeholder="End event date"
            />
          </Grid.Col>
          <Grid.Col md={6} lg={3}>
            <TextInput
              withAsterisk
              placeholder="Event name"
              {...register("name")}
              label="Event name"
              error={errors.name?.message as string}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={3}>
            <TextInput
              withAsterisk
              placeholder="Slug name"
              {...register("slug")}
              label="Slug name"
              error={errors.slug?.message as string}
            />
          </Grid.Col>
        </Grid>
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
    {
      input: (
        <Grid>
          {/* <Grid.Col md={6} lg={4}>
            <Controller
              name="is_next_event"
              control={control}
              render={({ field }) => (
                <Select
                  withAsterisk
                  {...field}
                  data={selectOptions}
                  value={nextEvent}
                  onChange={handleNextEvent}
                  label="Is this Next event?"
                  placeholder="Is this Next event?"
                  error={errors.is_next_event?.message as string}
                  my={20}
                />
              )}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={4}>
            <Controller
              name="is_current_upcoming_event"
              control={control}
              render={({ field }) => (
                <Select
                  withAsterisk
                  {...field}
                  data={selectOptions}
                  value={upcoming}
                  onChange={handleUpcoming}
                  label="Is this upcoming event?"
                  placeholder="Is this upcoming event?"
                  error={errors.is_current_upcoming_event?.message as string}
                  my={20}
                />
              )}
            />
          </Grid.Col> */}
        </Grid>
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
    {
      input: (
        <Grid>
          <Grid.Col md={12} lg={3}>
            <MainHotelSearch />
          </Grid.Col>
          <Grid.Col md={12} lg={3}>
            <AlternateHotelsSearch />
          </Grid.Col>
          <Grid.Col md={6} lg={3}>
            <TextInput
              placeholder="Whova id"
              {...register("whova_event_id")}
              label="Whova event Id"
              error={errors.whova_event_id?.message as string}
            />
          </Grid.Col>
        </Grid>
      ),
      span: {
        lg: 12,
        md: 6,
      },
    },
  ];

  return (
    <Grid justify="space-around" p={10}>
      {eventDetailsForm.map((event: any, key: number) => (
        <Grid.Col md={event.span.md} lg={event.span.lg} key={key}>
          {event.input}
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default EventDetailsForm;
