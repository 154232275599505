import { useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { Button } from "@mantine/core";
import SponsorshipDetailsForm from "./SponsorshipDetailsForm";
import { useCreateSponsorship } from "services/enteties/sponsorships/sponsorship-package";
import { showNotification } from "@mantine/notifications";
import { useGetSponsorshipFeatures } from "services/enteties/sponsorships";
import { useSyncFeatures } from "services/enteties/sponsorships/sponsorship-feature";
const CreateSponsorship = ({id}:{id: string | undefined}) => {
  const methods = useForm({
    resolver: yupResolver(yup.object({})),
  });
  const { handleSubmit } = methods;

  const {
    mutate: createSponsorship,
    data: response,
    isSuccess,
  } = useCreateSponsorship();
  const { mutate } = useSyncFeatures();
  const { data } = useGetSponsorshipFeatures();

  const features = data?.map((d: any) => ({ id: d.id, value: 1 }));

  const onSubmit = (data: any) => {
    if (features.length)
      createSponsorship(
        {
          ...data,
          price: data.price*100,
          is_main: data.is_main ? 1 : 0,
          is_published: data.is_published ? 1 : 0,
          event_id: id,
        },
        {
          onSuccess: () => {
            showNotification({
              title: "Success",
              message: "Successfully created",
            });
            // handleClose(false);
          },
          onError: (error: any) => {
            showNotification({
              color: "red",
              title: "Error",
              message: error.response.data.message,
            });
          },
        }
      );
  };
  useMemo(() => {
    if (isSuccess) {
      mutate({ id: response?.data.id, data: features });
    }
  }, [isSuccess]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <SponsorshipDetailsForm />
        </FormProvider>
        <Button type="submit">Create Sponsorship</Button>
      </form>
    </div>
  );
};

export default CreateSponsorship;
