import { Select } from "@mantine/core";
import { useGetSpeechTypes } from "services/enteties/utilities";
import { useFormContext, Controller } from "react-hook-form";

const SpeechTypeList = () => {
  const { data } = useGetSpeechTypes();
  const { control } = useFormContext();

  return (
    <Controller
      name="speech_type"
      control={control}
      render={({ field }) => (
        <Select
          withAsterisk
          {...field}
          searchable
          placeholder="Speech type"
          label="Speech type"
          data={data ?? []}
        />
      )}
    />
  );
};

export default SpeechTypeList;
