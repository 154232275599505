import { Skeleton, Accordion, Button, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import PageWrapper from "components/core/PageWrapper";
import TicketForm from "components/forms/tickets/TicketForm";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useGetTicket } from "services/enteties/tickets/useGetTicket";
import { useUpdateTicket } from "services/enteties/tickets/useUpdateTicket";
import { TicketData } from "types/ticket";

const Ticket = () => {
	const { id } = useParams();
	const [value, setAccordion] = useState<string[]>(["item-1"]);

	const { data: ticket, isLoading } = useGetTicket(id);
	const price = ticket?.price ? ticket?.price / 100 : undefined;

	const { mutate } = useUpdateTicket(id);

	const methods = useForm<TicketData>({
		defaultValues: {
			name: ticket?.name,
			price: price,
			type: ticket?.type,
			start_date: ticket?.start_date,
			end_date: ticket?.end_date,
			is_available: ticket?.is_available,
		},
	});

	const { handleSubmit, reset } = methods;

	useEffect(() => {
		if (ticket !== undefined)
			reset({
				...ticket,
				price: price,
				is_available: ticket.is_available ? "1" : "0",
			});
	}, [price, reset, ticket]);

	const onSubmit = (data: TicketData) => {
		mutate(
			{ ...data, price: data.price * 100 },
			{
				onSuccess: () => {
					showNotification({
						title: "Success",
						message: "Successfully updated ticket",
					});
				},
				onError: (error: any) =>
					showNotification({
						title: "Error",
						message: error.response.data.message,
						color: "red",
					}),
			}
		);
	};

	return (
		<PageWrapper title="Tickets" navigateBack>
			{isLoading ? (
				<Skeleton height={250} />
			) : (
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Accordion
							radius="md"
							variant="separated"
							multiple
							value={value}
							onChange={setAccordion}
						>
							<Accordion.Item value="item-1">
								<Accordion.Control>
									<Text fw={500}>Edit Ticket</Text>
								</Accordion.Control>
								<Accordion.Panel p="md">
									<TicketForm />
								</Accordion.Panel>
							</Accordion.Item>
						</Accordion>
						<Button type="submit" mt={20}>
							Update ticket
						</Button>
					</form>
				</FormProvider>
			)}
		</PageWrapper>
	);
};

export default Ticket;
