import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export const useDeleteEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (eventId: any) =>
      http.post(`/api/events/${eventId}`, { _method: "DELETE" }),
    onSuccess: () => {
      queryClient.invalidateQueries(["events"]);
    },
  });
};
