import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export const useDeleteSponsorFeature = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ featureIndex }: any) =>
      http.post(`/api/sponsorships-features/${featureIndex}`, {
        _method: "DELETE",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["sponsorships-features"]);
    },
  });
};
