import {
	TextInput,
	Button,
	Text,
	Card,
	Anchor,
	Space,
	Flex,
} from "@mantine/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ResetPasswordData } from "types/auth";
import { useResetPassword } from "services/enteties/auth/useResetPassword";

const ResetPasswordForm = () => {
	const params = new URLSearchParams(window.location.search);
	const token = params.get("token") ?? "";
	const email = params.get("email") ?? "";

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ResetPasswordData>({
		defaultValues: {
			email: email,
			password: "",
			password_confirmation: "",
			token,
		},
		resolver: yupResolver(
			yup.object({
				email: yup
					.string()
					.email("Must be valid email")
					.required("Email is required"),
				password: yup.string().required("Password is required"),
				password_confirmation: yup
					.string()
					.required("Confirm your password")
					.oneOf([yup.ref("password")], "Passwords must match"),
			})
		),
	});
	const { mutate: resetPassword, isLoading } = useResetPassword();

	const onSubmit = ({
		email,
		password,
		password_confirmation,
		token,
	}: ResetPasswordData) => {
		resetPassword({ email, password, password_confirmation, token });
	};

	return (
		<Card sx={{ width: 350, margin: "100px auto" }} radius="md" withBorder>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Text fz="lg">Reset password</Text>
				<Text fz="xs" my={10}>
					The password field must contain at least one uppercase and
					one lowercase letter, symbol and number
				</Text>
				<TextInput
					label="Email"
					{...register("email")}
					placeholder="Email"
					my={10}
					error={errors.email?.message as string}
				/>
				<TextInput
					label="Password"
					{...register("password")}
					placeholder="Password"
					type="password"
					my={20}
					error={errors.password?.message as string}
				/>
				<TextInput
					label="Confirm password"
					{...register("password_confirmation")}
					placeholder="Confirm password"
					type="password"
					my={20}
					error={errors.password_confirmation?.message as string}
				/>
				<Button
					type="submit"
					variant="gradient"
					loading={isLoading}
					fullWidth
				>
					Change password
				</Button>
			</form>
			<Space h={"md"} />
			<Flex justify={"center"}>
				<Anchor size={"sm"} href="/login">
					Back to login
				</Anchor>
			</Flex>
		</Card>
	);
};

export default ResetPasswordForm;
