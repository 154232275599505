import { Loader, Text } from "@mantine/core";
import { Control, Controller, FieldValues } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./wysiwyg.css";

interface WysiwygProps {
	name: string;
	control: Control<FieldValues>;
	label?: string;
	loading?: boolean;
}

export default function Wysiwyg({
	name,
	control,
	label,
	loading,
}: WysiwygProps) {
	return (
		<>
			{label && (
				<Text
					fz="sm"
					sx={{
						fontFamily: "Greycliff CF, sans-serif",
					}}
				>
					{label}
				</Text>
			)}
			<Controller
				name={name}
				control={control}
				render={({ field }) =>
					(loading && (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								border: "1px solid #ced4da",
								padding: "6px 12px",
								borderRadius: 4,
								background: "#f1f3f5",
							}}
						>
							<Loader size="sm" />
						</div>
					)) || (
						<ReactQuill
							theme="snow"
							value={field.value}
							onChange={field.onChange}
						/>
					)
				}
			/>
		</>
	);
}
