import { createContext, PropsWithChildren, useState } from "react";
// interface IFUser {
//   email: string;
//   name: string;
//   created_at: string;
// }

// interface IFUserInterface {
//   auth: boolean | undefined;
//   setAuth: (auth: boolean | undefined) => void;
//   setUser: (user: IFUser | Record<any, never>) => void;
//   user: IFUser | Record<any, never>;
// }
const SponsorshipContext = createContext({} as any);
export const SponsorshipContextProvider = ({ children }: PropsWithChildren) => {
  const [sponsorshipData, setSponsorshipData] = useState([]);
  const handleSponsorshipData = (data: any) => {
    setSponsorshipData(data);
  };
  return (
    <SponsorshipContext.Provider
      value={{ sponsorshipData, handleSponsorshipData }}
    >
      {children}
    </SponsorshipContext.Provider>
  );
};

export default SponsorshipContext;
