import http from "services/http";
import { useQuery } from "@tanstack/react-query";

export const useGetCountries = () => {
  return useQuery(["countries"], () => http.get(`api/countries`), {
    select(data) {
      return Object.keys(data.data).map((key) => {
        return { value: key, label: data.data[key] };
      });
    },
    refetchOnWindowFocus: false,
  });
};
