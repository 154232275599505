import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetSpeaker = (userId: string | undefined) => {
  return useQuery(
    ["speaker", userId],
    () => http.get(`/api/speakers/${userId}`),
    {
      enabled: !!userId,
      select(data) {
        return data.data;
      },
    }
  );
};
