import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateSponsorship = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => http.post(`/api/sponsorships`, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["sponsorships-details"]);
      queryClient.invalidateQueries(["sponsorships-main"]);
      queryClient.invalidateQueries(["sponsorships-base"]);
    },
  });
};
