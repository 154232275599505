import http from "services/http";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { ResetPasswordData } from "types/auth";

export const useResetPassword = () => {
	const navigate = useNavigate();
	return useMutation({
		mutationFn: async ({
			email,
			password,
			password_confirmation,
			token,
		}: ResetPasswordData) => {
			await http.get("/sanctum/csrf-cookie");
			return await http.post("/reset-password", {
				email,
				password,
				password_confirmation,
				token,
			});
		},
		onSuccess: () => {
			navigate("/admin");
		},
		onError: (error: any) => {
			if (error?.response?.data) {
				showNotification({
					title: "Error",
					message: error?.response?.data?.message,
				});
			}
		},
	});
};
