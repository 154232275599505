import { useState, useContext } from "react";

import {
  Grid,
  Flex,
  Text,
  TextInput,
  Textarea,
  Button,
  Portal,
} from "@mantine/core";

import { useForm } from "react-hook-form";
import HotelContext from "context/HotelContext";
import { IconPlus } from "@tabler/icons";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CoreModal from "components/core/CoreModal";
import HowToGetCard from "./HowToGetCard";

const HowToGetThereForm = () => {
  const { howToGetThere, addHowToGetThere } = useContext(HotelContext);
  const [opened, setOpened] = useState(false);

  const container = document.createElement("div");
  document.body.appendChild(container);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(
      yup.object({
        transportation_type: yup.string().required("Required field"),
      })
    ),
  });

  const forwardSave = (data: any) => {
    addHowToGetThere(data);
    setOpened(false);
    reset();
  };

  const handleSubmitWithoutPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(forwardSave)(e);
  };

  return (
    <>
      <Portal target={container}>
        <CoreModal
          opened={opened}
          onClose={() => setOpened(false)}
          title={"Add new option"}
          size={600}
        >
          <form onSubmit={handleSubmitWithoutPropagation}>
            <Grid>
              <Grid.Col md={12} lg={9}>
                <TextInput
                  withAsterisk
                  label="Transportation type"
                  placeholder="Transportation type"
                  {...register("transportation_type")}
                  error={errors.transportation_type?.message as string}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={3}>
                <TextInput
                  label="Time in minutes"
                  placeholder="Time to destination in minutes"
                  {...register("time_to_destination")}
                  type="number"
                />
              </Grid.Col>
              <Grid.Col md={12} lg={6}>
                <TextInput
                  label="Action text"
                  placeholder="Action text"
                  {...register("action_text")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={6}>
                <TextInput
                  label="Action link"
                  placeholder="Action link"
                  {...register("action_url")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Textarea
                  label="Description"
                  placeholder="Description"
                  {...register("description")}
                />
              </Grid.Col>
            </Grid>
            <Button mx="auto" type="submit" mt={20}>
              Add New
            </Button>
          </form>
        </CoreModal>
      </Portal>
      <Flex
        c="blue"
        align="center"
        justify="end"
        sx={{ cursor: "pointer" }}
        mb={20}
      >
        <Text onClick={() => setOpened(true)} mx={10}>
          Add new
        </Text>
        <IconPlus size={20} />
      </Flex>

      {howToGetThere.length > 0 ? (
        <Grid>
          {howToGetThere.map((details: any, key: number) => (
            <Grid.Col md={6} key={key}>
              <HowToGetCard details={details} id={key} />
            </Grid.Col>
          ))}
        </Grid>
      ) : (
        <Text>There are no details on how to get there</Text>
      )}
    </>
  );
};

export default HowToGetThereForm;
