import { createContext, PropsWithChildren, useState } from "react";
interface IFUser {
  email: string;
  name: string;
  created_at: string;
}

interface IFUserInterface {
  auth: boolean | undefined;
  setAuth: (auth: boolean | undefined) => void;
  setUser: (user: IFUser | Record<any, never>) => void;
  user: IFUser | Record<any, never>;
}
const AuthContext = createContext({} as IFUserInterface);
export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState({});
  const [auth, setAuth] = useState<boolean | undefined>(false);

  return (
    <AuthContext.Provider value={{ setUser, user, auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
