import { useState } from "react";
import CoreTable from "components/core/CoreTable";
import {
  Flex,
  Button,
  Text,
  Switch,
  Select,
  Loader,
  TextInput,
} from "@mantine/core";
import { IconCheck, IconSearch, IconX } from "@tabler/icons";
import { useGetAllSpeakers } from "services/enteties/speakers";
import { useNavigate } from "react-router-dom";
import PageWrapper from "components/core/PageWrapper";
import { useDeleteSpeaker } from "services/enteties/speakers";
import CoreModal from "components/core/CoreModal";
import { showNotification } from "@mantine/notifications";
import { useUpdateHighlight } from "services/enteties/speakers/useUpdateHighlight";
import { debounce } from "lodash";
import { useGetAllEventsContextMenu } from "services/enteties/events/useGetAllEventsContextMenu";
import { Event } from "types/event";

const Speakers = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEvent, setSelectedEvent] = useState<string>("");
  const [page, setPage] = useState(1);
  const [deleteId, setDeleteId] = useState<any>("");
  const { data, isLoading, isFetching, refetch } = useGetAllSpeakers(
    page,
    searchQuery,
    selectedEvent
  );
  const handlePagination = (page: number) => setPage(page);
  const { mutate: deleteSpeaker } = useDeleteSpeaker();
  const { mutate: updateIsHighlight } = useUpdateHighlight();
  const navigate = useNavigate();

  // Get events and map id and name
  const { data: eventsData, isLoading: isLoadingEvents } =
    useGetAllEventsContextMenu();
  const events = eventsData?.map((e: Event) => ({
    value: e.id,
    label: e.name,
  }));

  const handleEventChange = (value: string) => {
    setSelectedEvent(value);
    if (selectedEvent) refetch();
  };

  const handleToggleHighlight = (id: number, currentIsHighlighted: boolean) => {
    updateIsHighlight(
      {
        id,
        is_highlighted: Number(!currentIsHighlighted),
      },
      {
        onSuccess: () =>
          showNotification({
            title: "Success",
            message: "Successfully updated",
          }),
        onError: (error: any) =>
          showNotification({
            title: "Error",
            message: error.response.data.message,
          }),
      }
    );
  };

  const debouncedSearch = debounce((value: string) => {
    setSearchQuery(value);
  }, 500);

  const handleSearchSpeakers = () => (
    <Flex align="center" gap="xl">
      <Select
        placeholder="Select event"
        allowDeselect
        disabled={isLoadingEvents}
        icon={isLoadingEvents && <Loader size="sm" />}
        data={events ?? []}
        dropdownPosition="bottom"
        onChange={handleEventChange}
        searchable
      />
      <TextInput
        placeholder="Search"
        onChange={(event) => debouncedSearch(event.target.value)}
        icon={<IconSearch size={"0.8rem"} />}
      />
    </Flex>
  );

  const headerColumns = [
    { id: 1, title: "id" },
    { id: 2, title: "Summit" },
    { id: 3, title: "Approved" },
    { id: 4, title: "Name" },
    { id: 5, title: "Email" },
    { id: 6, title: "Job title" },
    { id: 7, title: "Country" },
    { id: 8, title: "Date" },
    { id: 9, title: "Highlighted" },
    { id: 10, title: "" },
  ];
  let rows = null;
  if (data?.data?.length) {
    rows = data?.data?.map((info: any, key: number) => (
      <tr key={key}>
        <td>{info.id}</td>
        <td>{info.event.name}</td>
        <td>
          {info.is_approved ? <IconCheck size={17} /> : <IconX size={17} />}
        </td>
        <td>{info.name}</td>
        <td>{info.email}</td>
        <td>{info.job_title}</td>
        <td>{info.country}</td>
        <td>{new Date(info.created_at).toLocaleDateString()}</td>
        <td>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Switch
              checked={info.is_highlighted}
              onChange={() =>
                handleToggleHighlight(info.id, info.is_highlighted)
              }
            />
          </div>
        </td>
        <td>
          <Button
            onClick={() => navigate(`/speakers/${info.id}`)}
            variant="subtle"
            size="xs"
          >
            Edit
          </Button>
          <Button
            onClick={() => setDeleteId(info.id)}
            variant="subtle"
            color="red"
            size="xs"
          >
            Delete
          </Button>
        </td>
      </tr>
    ));
  } else {
    rows = (
      <tr>
        <td
          colSpan={headerColumns.length}
          style={{
            textAlign: "center",
            padding: "20px 0px",
          }}
        >
          No results.
        </td>
      </tr>
    );
  }
  return (
    <PageWrapper title="Speakers" action={handleSearchSpeakers()}>
      <CoreModal
        opened={!!deleteId}
        onClose={() => setDeleteId("")}
        title={"Delete Speaker"}
      >
        <Text ta="center" fw={500} my={20}>
          Are you sure you want to delete this speaker?
        </Text>
        <Flex justify="space-evenly">
          <Button onClick={() => setDeleteId("")}>Cancel</Button>
          <Button
            onClick={() => {
              deleteSpeaker(deleteId);
              setDeleteId("");
            }}
          >
            Confirm
          </Button>
        </Flex>
      </CoreModal>
      <CoreTable
        rows={rows}
        page={page}
        headerColumns={headerColumns}
        perPage={data?.meta?.per_page}
        totalResults={data?.meta?.total}
        from={data?.meta?.from}
        to={data?.meta?.to}
        lastPage={data?.meta?.last_page}
        loading={isLoading}
        fetching={isFetching}
        handlePagination={data?.data?.length > 0 ? handlePagination : undefined}
      />
    </PageWrapper>
  );
};

export default Speakers;
