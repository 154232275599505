import { Grid, TextInput, Paper } from "@mantine/core";
import { useFormContext } from "react-hook-form";

import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandLinkedin,
  IconBrandSnapchat,
  IconBrandTiktok,
} from "@tabler/icons";

const SocialMediaLinks = () => {
  const { register } = useFormContext();
  const socials = [
    { title: "facebook", icon: <IconBrandFacebook size={20} /> },
    { title: "instagram", icon: <IconBrandInstagram size={20} /> },
    { title: "twitter", icon: <IconBrandTwitter size={20} /> },
    { title: "youtube", icon: <IconBrandYoutube size={20} /> },
    { title: "linkedin", icon: <IconBrandLinkedin size={20} /> },
    { title: "snapchat", icon: <IconBrandSnapchat size={20} /> },
    { title: "tiktok", icon: <IconBrandTiktok size={20} /> },
  ];

  return (
    <Grid>
      {socials.map((social: any, key: number) => (
        <Grid.Col md={6} xl={4} key={key}>
          <Paper withBorder p="xl">
            <Grid columns={4}>
              <Grid.Col span={3}>
                <TextInput
                  icon={social.icon}
                  placeholder={social.title}
                  label={
                    social.title.charAt(0).toUpperCase() + social.title.slice(1)
                  }
                  {...register(`social_media_links.${social.title}.url`)}
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <TextInput
                  label="Followers"
                  placeholder="Followers"
                  {...register(
                    `social_media_links.${social.title}.followers_count`,
                    { valueAsNumber: true }
                  )}
                  type="number"
                />
              </Grid.Col>
            </Grid>
          </Paper>
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default SocialMediaLinks;
