import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useAllHotelsPagination = (page: number) => {
  return useQuery(
    ["hotels", page],
    () => http.get(`/api/hotels?page=${page}`),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      select(data) {
        return data.data;
      },
    }
  );
};
