import { useMemo, useState, useContext } from "react";
import {
  Accordion,
  Text,
  Button,
  Grid,
  Skeleton,
  Divider,
} from "@mantine/core";
import { useGetHotel } from "services/enteties/hotels";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import FileUploadContext from "context/FileUploadContext";
import HotelContext from "context/HotelContext";
import PageWrapper from "components/core/PageWrapper";
import MultiplePhotosUpload from "components/core/ImageUpload/MultiplePhotosUpload";
import { useUpdateHotel } from "services/enteties/hotels";
import HotelDetailsForm from "components/forms/hotels/HotelDetailsForm";
import { showNotification } from "@mantine/notifications";
import HowToGetThereForm from "components/forms/hotels/HowToGetThereForm";
import { IconX } from "@tabler/icons";
import FileUpload from "components/core/ImageUpload/FileUpload";
const Hotel = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetHotel(id);
  const [value, setAccordion] = useState<string[]>([
    "item-1",
    "item-2",
    "item-3",
  ]);

  const { imageState, imagesState, cleanupContextState, fileState } =
    useContext(FileUploadContext);
  const { rating, howToGetThere, handleHowToGet, handleSetRating } =
    useContext(HotelContext);
  const { mutate } = useUpdateHotel(id);

  const methods = useForm({
    // resolver: yupResolver(
    //   yup.object({
    //     transportation_type: yup.string().required("Required field"),
    //   })
    // ),
    // resolver: yupResolver(yup.object().shape(hotelValidation())),
  });
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  useMemo(() => {
    const defaultValues = {
      name: data?.name ?? "",
      country: data?.country ?? "",
      address: data?.address ?? "",
      city: data?.city ?? "",
      type: data?.type ?? "",
      description: data?.description ?? "",
      created_at: data?.created_at ?? "",
      action_url: data?.action_url ?? "",
    };
    Object.keys(defaultValues).forEach((value: any) => {
      setValue(value, data?.[value]);
    });
    handleSetRating(data?.rating);
    handleHowToGet(data?.how_to_get_there ?? []);
  }, [setValue, data]);

  const contextImageFile = (name: string) =>
    imageState.filter((img: any) => img?.name === name)?.[0]?.file?.[0] ?? null;

  const onSubmit = (data: any) => {
    mutate(
      {
        rating,
        how_to_get_there: howToGetThere,
        ...data,
        ...(contextImageFile("hero_image") && {
          hero_image: contextImageFile("hero_image"),
        }),
        ...(contextImageFile("banner_image") && {
          banner_image: contextImageFile("banner_image"),
        }),
        ...(imagesState.length && { images: imagesState }),
        ...(fileState.length && { floor_plan: fileState[0] }),
      },
      {
        onSuccess: () => {
          showNotification({
            title: "Success",
            message: "Successfully updated",
          });
          cleanupContextState();
        },
        onError: (error: any) => {
          showNotification({
            title: "Error",
            message: error.response.data.message,
            color: "red",
          });
        },
      }
    );
  };

  useMemo(() => {
    if (Object.keys(errors).length)
      showNotification({
        color: "red",
        icon: <IconX />,
        title: "Error",
        message: "Please fill in the required fields",
      });
  }, [errors]);

  return (
    <PageWrapper title="Hotel details" navigateBack>
      {isLoading ? (
        Array.from(Array(value.length).keys()).map((key) => (
          <Skeleton key={key} height={250} radius="sm" my={20} />
        ))
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <Accordion
              radius="md"
              variant="separated"
              multiple
              value={value}
              onChange={setAccordion}
            >
              <Accordion.Item value="item-1">
                <Accordion.Control>
                  <Text fw={500}>Hotel Details</Text>
                </Accordion.Control>
                <Accordion.Panel p="md">
                  <HotelDetailsForm
                    currentPhoto={data?.hero_image?.path}
                    bannerPhoto={data?.banner_image?.path}
                  />
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="item-2">
                <Accordion.Control>
                  <Text fw={500}>How To Get There?</Text>
                </Accordion.Control>
                <Accordion.Panel p="md">
                  <HowToGetThereForm />
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="item-3">
                <Accordion.Control>
                  <Text fw={500}>Hotel Files</Text>
                </Accordion.Control>
                <Accordion.Panel p="md">
                  <Grid mb={20} justify="space-between">
                    <Grid.Col md={12} lg={5}>
                      <Text fw={400} mb={10}>
                        Hotel photos
                      </Text>
                      <MultiplePhotosUpload
                        placeholderText="Drag photos here or click to select"
                        currentPhotos={data?.images}
                        queryKey={"hotel"}
                      />
                    </Grid.Col>

                    <Divider size={1} orientation="vertical" />

                    <Grid.Col md={12} lg={5}>
                      <Text fw={400} mb={10}>
                        Hotel floor plan
                      </Text>
                      <FileUpload
                        placeholderText="Drag your file or click to select"
                        currentFiles={data?.floor_plan}
                        maxFiles={1}
                      />
                    </Grid.Col>
                  </Grid>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
            <Button type="submit" mt={20}>
              Update Hotel
            </Button>
          </FormProvider>
        </form>
      )}
    </PageWrapper>
  );
};

export default Hotel;
