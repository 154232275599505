import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetSponsorshipsByEventContextMenu = (id: string | undefined | null) => {
	return useQuery(
		["sponsorships-by-event", id],
		() => {
			if (!id) return Promise.resolve(null);
			return http.get(`/api/sponsorships/contextmenu?filter[event_id]=${id}`);
		},
		{
			enabled: !!id,
			select(data) {
				if (data) {
					return data.data?.map((sponsor: any) => ({
						value: sponsor.id.toString(),
						label: sponsor.name,
					}));
				}
				return null;
			},
		}
	);
};
