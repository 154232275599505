import { useContext } from "react";
import HotelContext from "context/HotelContext";
import CoreMultiselect from "components/core/CoreMultiselect";
import { useFilterRegular } from "services/enteties/hotels";

const AlternativeHotelsSearch = () => {
  const {
    alternateHotels,
    debouncedSearchHotels,
    handleDebouncedSearchHotels,
    handleAlternateHotels,
  } = useContext(HotelContext);

  const { data: alternateHotelsData, isFetching: fetchingAlternateHotels } =
    useFilterRegular(debouncedSearchHotels);

  return (
    <CoreMultiselect
      maxLimit={5}
      placeholder="Alternate Hotels"
      label="Alternate Hotels"
      handleDebounced={handleDebouncedSearchHotels}
      data={alternateHotelsData}
      isFetching={fetchingAlternateHotels}
      setSelected={handleAlternateHotels}
      selected={alternateHotels}
    />
  );
};

export default AlternativeHotelsSearch;
