import { useContext } from "react";
import { Link } from "react-router-dom";
import FileUploadContext from "context/FileUploadContext";
import {
  Box,
  ActionIcon,
  Card,
  Text,
  createStyles,
  Select,
  Loader,
} from "@mantine/core";
import { Dropzone, MS_EXCEL_MIME_TYPE } from "@mantine/dropzone";
import { IconX, IconFile } from "@tabler/icons";
import { useDeleteFile } from "services/enteties/utilities";
import { useGetAllEventsContextMenu } from "services/enteties/events/useGetAllEventsContextMenu";
import { Event } from "types/event";

const useStyles = createStyles((theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
    boxShadow: theme.shadows.md,
    width: "100%",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: 20,
    backgroundColor: theme.colors.blue[1],
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  text: {
    overflow: "hidden",
    paddingRight: "50px",
    paddingLeft: "10px",
  },
}));

const ExcelFileUpload = ({
  placeholderText,
  currentFiles,
  maxFiles,
  setEventId,
}: any) => {
  const { data: eventsData, isLoading: isLoadingEvents } =
    useGetAllEventsContextMenu();
  const events = eventsData?.map((e: Event) => ({
    value: e.id,
    label: e.name,
  }));

  const {
    fileState,
    deleteFile: deleteCurrent,
    setFileState,
  } = useContext(FileUploadContext);
  const { mutate: deleteFile } = useDeleteFile("file");
  const { classes } = useStyles();
  const currentFilesStatus = currentFiles && Object.keys(currentFiles).length;

  const handleEventChange = (value: string) => {
    setEventId(value);
  };

  const myComponent = ({ id, original_name, path, deleteAction }: any) => (
    <Box my={20} key={id} className={classes.card}>
      <Box className={classes.icon}>
        <IconFile size={25} />
      </Box>
      <Link
        className={classes.text}
        to={path}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text>{original_name}</Text>
      </Link>
      <ActionIcon onClick={() => deleteAction(id)} mx={10}>
        <IconX size={15} />
      </ActionIcon>
    </Box>
  );

  const existingFilesDisplay = () =>
    myComponent({
      id: currentFiles.id,
      original_name: currentFiles.original_name,
      path: currentFiles.path,
      deleteAction: deleteFile,
    });

  const newFilesDisplay = () =>
    fileState.map((file: any, key: number) => {
      return myComponent({
        id: key,
        original_name: file.name,
        path: file.path,
        deleteAction: deleteCurrent,
      });
    });

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100%",
      }}
    >
      <Box miw="100%">
        <Select
          placeholder="Select event"
          allowDeselect
          disabled={isLoadingEvents}
          icon={isLoadingEvents && <Loader size="sm" />}
          data={events ?? []}
          dropdownPosition="bottom"
          onChange={handleEventChange}
          searchable
          mb="md"
        />
        <Dropzone
          maxFiles={maxFiles}
          accept={MS_EXCEL_MIME_TYPE}
          onDrop={setFileState}
          disabled={
            currentFilesStatus || fileState.length >= maxFiles ? true : false
          }
        >
          <Text ta="center">{placeholderText}</Text>
          <Text ta="center" fz="sm" color="dimmed">
            or drag over
          </Text>
        </Dropzone>
      </Box>

      {currentFilesStatus ? existingFilesDisplay() : newFilesDisplay()}
    </Card>
  );
};

export default ExcelFileUpload;
