import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export const useDeleteSponsorship = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: any) =>
      http.post(`/api/sponsorships/${id}`, { _method: "DELETE" }),
    onSuccess: (id) => {
      queryClient.invalidateQueries(["sponsorships-details"]);
      queryClient.invalidateQueries(["sponsorships-by-event"]);
      queryClient.invalidateQueries(["sponsorships-main"]);
      queryClient.invalidateQueries(["sponsorships-base"]);
    },
  });
};
