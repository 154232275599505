import {
	TextInput,
	Button,
	Text,
	Card,
	Anchor,
	Space,
	Flex,
} from "@mantine/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLogin } from "services/enteties/auth";
import { LoginData } from "types/auth";

const LoginForm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginData>({
		resolver: yupResolver(
			yup.object({
				email: yup
					.string()
					.email("Must be valid email")
					.required("Email is required"),
				password: yup.string().required("Password is required"),
			})
		),
	});
	const { mutate: login, isLoading } = useLogin();

	const onSubmit = ({ email, password }: LoginData) => {
		login({ email, password });
	};

	return (
		<Card sx={{ width: 350, margin: "100px auto" }} radius="md" withBorder>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Text fz="lg">Panel login</Text>
				<Text fz="xs" my={10}>
					Your username and password are case-sensitive, so please
					enter them carefully!
				</Text>
				<TextInput
					label="Email"
					{...register("email")}
					placeholder="Email"
					my={10}
					error={errors.email?.message as string}
				/>
				<TextInput
					label="Password"
					{...register("password")}
					placeholder="Password"
					type="password"
					my={20}
					error={errors.password?.message as string}
				/>
				<Button
					type="submit"
					variant="gradient"
					loading={isLoading}
					fullWidth
				>
					Login
				</Button>
			</form>
			<Space h={"md"} />
			<Flex justify={"flex-end"}>
				<Anchor size={"sm"} href="/forgot-password">
					Forgot password?
				</Anchor>
			</Flex>
		</Card>
	);
};

export default LoginForm;
