import {
  IconAffiliate,
  IconCrown,
  IconFriends,
  IconCalendarEvent,
  IconNews,
  IconBuildingSkyscraper,
  IconMail,
  IconFile,
  IconUsers,
  IconTools,
  IconSend,
  IconSpeakerphone,
  IconTarget,
  IconTicket,
} from "@tabler/icons";
const navLinks = [
  // {
  //   title: "Structure",
  //   ref: "",
  //   icon: <IconAffiliate size={20} />,
  //   submenu: [
  //     { subtitle: "Structure", ref: "/admin" },
  //     { subtitle: "Cover", ref: "" },
  //   ],
  // },
  {
    title: "Sponsorships",
    ref: "/sponsorships",
    icon: <IconCrown size={20} />,
  },

  {
    title: "Participants",
    ref: "/participants",
    icon: <IconFriends size={20} />,
  },
  // {
  //   title: "Participants",
  //   ref: "",
  //   icon: <IconFriends size={20} />,
  //   submenu: [
  //     { subtitle: "Companies", ref: "" },
  //     { subtitle: "Participants", ref: "" },
  //     { subtitle: "Participants import", ref: "" },
  //     { subtitle: "Number of participants", ref: "" },
  //   ],
  // },
  {
    title: "Speakers",
    icon: <IconSpeakerphone size={20} />,
    ref: "speakers",
  },

  // {
  //   title: "News",
  //   icon: <IconNews size={20} />,
  //   ref: "",
  // },
  {
    title: "Events",
    icon: <IconCalendarEvent size={20} />,
    ref: "/events",
  },
  {
    title: "Hotels",
    icon: <IconBuildingSkyscraper size={20} />,
    ref: "/hotels",
    // submenu: [
    //   { subtitle: "Hotels", ref: "" },
    //   { subtitle: "Homepage banner", ref: "" },
    // ],
  },
  // {
  //   title: "Email",
  //   icon: <IconMail size={20} />,
  //   ref: "",
  // },
  // {
  //   title: "Sponsor Expose",
  //   icon: <IconTarget size={20} />,
  //   ref: "",
  // },
  {
    title: "Tickets",
    icon: <IconTicket size={20} />,
    ref: "/tickets",
  },
  {
    title: "File manager",
    icon: <IconFile size={20} />,
    ref: "/file-manager",
  },
  // {
  //   title: "Users",
  //   icon: <IconUsers size={20} />,
  //   ref: "",
  // },
  // {
  //   title: "Tools",
  //   icon: <IconTools size={20} />,
  //   ref: "",
  //   submenu: [{ subtitle: "Lists", ref: "" }],
  // },
  // {
  //   title: "Codes",
  //   icon: <IconTicket size={20} />,
  //   ref: "",
  // },
  {
    title: "Email logs",
    ref: "/email-logs",
    icon: <IconMail size={20} />,
  },
  {
    title: "Newsletter",
    icon: <IconSend size={20} />,
    ref: "/newsletter",
  },
];
export default navLinks;
