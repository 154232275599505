import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetColorSchemes = () => {
	return useQuery(["color_schemes"], () => http.get(`api/color-schemes`), {
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		select(data) {
			return data.data;
		},
	});
};
