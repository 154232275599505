import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useApproveSpeaker = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string | undefined) =>
      http.post(`/api/speakers/${id}/approve`, { _method: "PATCH" }),
    onSuccess: () => {
      queryClient.invalidateQueries(["speakers"]);
    },
  });
};
