import http from "services/http";
import { useQuery } from "@tanstack/react-query";

export const useGetSpeechTypes = () => {
  return useQuery(["speech-types"], () => http.get(`api/speech-types`), {
    select(data) {
      return Object.keys(data.data).map((key) => {
        return { value: key, label: data.data[key] };
      });
    },
    refetchOnWindowFocus: false,
  });
};
