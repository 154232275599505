import { createContext, PropsWithChildren, useState, useMemo } from "react";
import { FileWithPath } from "@mantine/dropzone";
import { useLocation } from "react-router-dom";

interface FileUpload {
  imagesState: FileWithPath[];
  imageState: IFImage[];
  fileState: FileWithPath[];
  setFileState: (file: FileWithPath[]) => void;
  setImageState: (file: IFImage[]) => void;
  setImagesState: (file: FileWithPath[]) => void;
  deleteImageInImages: (index: number) => void;
  cleanupContextState: () => void;
  deleteFile: (index: number) => void;
}

interface IFImage {
  name: string | undefined;
  file: FileWithPath[];
}

const FileUploadContext = createContext({} as FileUpload);

export const FileUploadContextProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const [imageState, setSingleImage] = useState<IFImage[]>([]);
  const [imagesState, setMultipleImages] = useState<FileWithPath[]>([]);
  const [fileState, setFile] = useState<FileWithPath[]>([]);

  const setImageState = (file: IFImage[]) => setSingleImage(file);

  const setImagesState = (file: FileWithPath[]) =>
    setMultipleImages([...imagesState, ...file]);

  const setFileState = (file: FileWithPath[]) => setFile(file);

  const deleteImageInImages = (index: number) =>
    setMultipleImages(
      imagesState.filter((item: FileWithPath) => item !== imagesState[index])
    );

  const deleteFile = (fileIndex: number) =>
    setFile(
      fileState.filter((item: FileWithPath) => item !== fileState[fileIndex])
    );

  const cleanupContextState = () => {
    setSingleImage([]);
    setMultipleImages([]);
    setFile([]);
  };

  useMemo(() => {
    if (location.pathname) {
      cleanupContextState();
    }
  }, [location.pathname]);

  return (
    <FileUploadContext.Provider
      value={{
        imageState,
        imagesState,
        fileState,
        setFileState,
        setImageState,
        setImagesState,
        cleanupContextState,
        deleteImageInImages,
        deleteFile,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

export default FileUploadContext;
