import {
  Header,
  rem,
  createStyles,
  Burger,
  Transition,
  Paper,
  NavLink,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import navLinks from "./navLinks";
import ProfileWidget from "../ProfileWidget";
import { IFNavigationLink, IFSubMenuFNavLinks } from "types/navigation";

const NAVIGATION_TOP = rem(90);
const useStyles = createStyles((theme) => ({
  header: {
    position: "relative",
    display: "flex",
    padding: 40,
    alignItems: "center",
    justifyContent: "end",
    [theme.fn.smallerThan("md")]: {
      justifyContent: "space-between",
      padding: 30,
    },
  },
  dropdown: {
    position: "absolute",
    top: NAVIGATION_TOP,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },
}));

const CoreHeader = () => {
  const { classes } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const navigate = useNavigate();
  const links = navLinks.map((link: IFNavigationLink, key: number) =>
    !link.submenu ? (
      <NavLink label={link.title} onClick={() => navigate(link.ref)} />
    ) : (
      <NavLink label={link.title} key={`${link.title}-${key}`}>
        {link.submenu.map((sub: IFSubMenuFNavLinks, key: number) => (
          <NavLink
            label={sub.subtitle}
            onClick={() => navigate(link.ref)}
            key={`${sub.subtitle}-${key}`}
          />
        ))}
      </NavLink>
    )
  );

  return (
    <Header height={0} className={classes.header}>
      <ProfileWidget />
      <Burger
        opened={opened}
        onClick={toggle}
        className={classes.burger}
        size="sm"
      />
      <Transition transition="pop-top-right" duration={200} mounted={opened}>
        {(styles) => (
          <Paper withBorder className={classes.dropdown} style={styles}>
            {links}
          </Paper>
        )}
      </Transition>
    </Header>
  );
};

export default CoreHeader;
