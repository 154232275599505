import http from "services/http";
import { useQuery } from "@tanstack/react-query";

export const useGetAllSpeakers = (
  page: number,
  searchQuery: string = "",
  eventFilter?: string
) => {
  let queryParams = `page=${page}`;

  if (searchQuery) {
    queryParams += `&filter[search]=${searchQuery}`;
  }

  if (eventFilter) {
    queryParams += `&filter[event]=${eventFilter}`;
  }

  return useQuery(
    ["speakers", page, searchQuery, eventFilter],
    () => http.get(`/api/speakers?include=event&${queryParams}`),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      select(data) {
        return data.data;
      },
    }
  );
};
