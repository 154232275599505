import { ReactNode } from "react";
import { Box, Flex, Text, Paper, Button } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
interface IFWrapperProps {
  children: ReactNode;
  title?: string;
  navigateBack?: boolean;
  action?: ReactNode;
}
const PageWrapper = ({
  children,
  title,
  navigateBack,
  action,
}: IFWrapperProps) => {
  const navigate = useNavigate();
  return (
    <Paper p="md" sx={{ backgroundColor: "transparent" }}>
      <>
        <Flex align="center" justify="space-between" py={10}>
          <Text sx={{ fontSize: 28, fontWeight: 100 }}>{title}</Text>
          <Flex miw={280} align="center" justify={"end"}>
            <Box mx={navigateBack ? 40 : 0}>{action}</Box>

            {navigateBack && (
              <Button
                variant="outline"
                leftIcon={<IconArrowLeft />}
                color="dark"
                radius="xl"
                fw={400}
                my={10}
                onClick={() => navigate(-1)}
              >
                Go Back
              </Button>
            )}
          </Flex>
        </Flex>
        {children}
      </>
    </Paper>
  );
};

export default PageWrapper;
