import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateFeature = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ featureIndex, name }: any) =>
      http.post(`/api/sponsorships-features/${featureIndex}`, {
        _method: "PATCH",
        name,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["sponsorships-features"]);
    },
  });
};
