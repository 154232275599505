import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDuplicateEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: any) => http.post(`/api/events/${id}/duplicate`),
    onSuccess: () => {
      queryClient.invalidateQueries(["events"]);
    },
  });
};
