import { Grid, Textarea } from "@mantine/core";

import { useFormContext } from "react-hook-form";
const SpeakerBioAndPublications = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const speakerBioPublicationsInputs = [
    {
      input: (
        <Textarea
          withAsterisk
          {...register("speaker_bio")}
          placeholder="Speakers Bio"
          label="Speakers Bio"
          minRows={4}
          maxRows={8}
          error={errors.speaker_bio?.message as string}
        />
      ),
      span: {
        lg: 5,
        md: 12,
      },
    },
    {
      input: (
        <Textarea
          withAsterisk
          {...register("publications")}
          placeholder="Publications"
          label="Publications"
          minRows={4}
          maxRows={8}
          error={errors.publications?.message as string}
        />
      ),
      span: {
        lg: 5,
        md: 12,
      },
    },
  ];
  return (
    <Grid justify="space-around">
      {speakerBioPublicationsInputs.map((input: any, key: number) => (
        <Grid.Col md={input.span.md} lg={input.span.lg} key={key}>
          {input.input}
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default SpeakerBioAndPublications;
