import http from "services/http";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import AuthContext from "context/AuthContext";
export const useAuthUser = () => {
  const { auth, setAuth, setUser } = useContext(AuthContext);
  return useQuery(["user"], () => http.get("/api/user"), {
    enabled: !auth,
    select(data) {
      return data.data;
    },
    onSuccess: (data) => {
      setAuth(true);
      setUser(data);
    },
  });
};
