import { useQuery } from "@tanstack/react-query";
import http from "services/http";

export const useGetParticipants = (
  page: number,
  searchQuery?: string,
  eventFilter?: string
) => {
  let queryParams = `page=${page}`;

  if (eventFilter) {
    queryParams += `&filter[event_id]=${eventFilter}`;
  }

  if (searchQuery) {
    queryParams += `&filter[name]=${searchQuery}`;
  }

  return useQuery(
    ["participants-all", page, searchQuery, eventFilter],
    () => http.get(`/api/participants?${queryParams}`),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      select(data) {
        return data.data;
      },
    }
  );
};
