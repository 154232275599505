import http from "services/http";
import { useMutation } from "@tanstack/react-query";
import { ForgotPasswordData } from "types/auth";

export const useForgotPassword = () => {
	return useMutation({
		mutationFn: ({ email }: ForgotPasswordData) =>
			http.post(`/forgot-password`, { email }),
	});
};
