import { useState, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import { useGetSpeaker } from "services/enteties/speakers";
import { Accordion, Button, Text, Skeleton } from "@mantine/core";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SpeakerPersonalDetailsForm from "components/forms/speakers/SpeakerPersonalDetailsForm";
import CompanyDetailsForm from "components/forms/speakers/CompanyDetailsForm";
import PresentationDetailsForm from "components/forms/speakers/PresentationDetailsForm";
import SpeakerBioAndPublications from "components/forms/speakers/SpeakerBioAndPublications";
import SocialMediaLinks from "components/forms/speakers/SocialMediaLinks";
import PageWrapper from "components/core/PageWrapper";
import { useUpdateSpeaker } from "services/enteties/speakers/useUpdateSpeaker";
import { showNotification } from "@mantine/notifications";
import FileUploadContext from "context/FileUploadContext";
import PreviousSpeakingExperiences from "components/forms/speakers/PreviousSpeakingExperiences";
import { useApproveSpeaker } from "services/enteties/speakers";
import { IconX, IconCheck } from "@tabler/icons";
const Speaker = () => {
  const { id } = useParams();

  const { data, isLoading, isFetched } = useGetSpeaker(id);
  const [value, setAccordion] = useState<string[]>([
    "item-1",
    "item-2",
    "item-3",
    "item-5",
    "item-4",
    "item-6",
  ]);
  const [approved, setApproved] = useState(true);
  const { imageState } = useContext(FileUploadContext);
  const { mutate } = useUpdateSpeaker();
  const { mutate: approveSpeaker, isLoading: loadingApprove } =
    useApproveSpeaker();
  const methods = useForm({
    resolver: yupResolver(
      yup.object({
        honorific: yup.string().required("Required field"),
        name: yup.string().required("Required field"),
        middle_name: yup.string().required("Required field"),
        job_title: yup.string().required("Required field"),
        city: yup.string().required("Required field"),
        email: yup.string().required("Required field"),
        phone: yup.string().required("Required field"),
        company_name: yup.string().required("Required field"),
        company_website: yup
          .string()
          .required("Required field")
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "Enter valid URL"
          ),
        company_description: yup.string().required("Required field"),
        content_of_presentations: yup.string().required("Required field"),
        panel_name: yup.string().required("Required field"),
        speech_type: yup.string().required("Required field"),
        publications: yup.string().required("Required field"),
        speaker_bio: yup.string().required("Required field"),
      })
    ),
  });
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const contextImageFile = (name: string) =>
    imageState.filter((img: any) => img?.name === name)?.[0]?.file?.[0] ?? null;

  const checkAvailableSocials = (links: any) => {
    const social_media_links: any = {};
    Object.keys(links).map(
      (value: any) =>
        links[value].url !== "" &&
        Object.assign(social_media_links, {
          [value]: links[value],
        })
    );
    return social_media_links;
  };

  const onSubmit = (data: any) => {
    const social_media_links = checkAvailableSocials(data.social_media_links);
    const rest = { ...data, social_media_links };
    mutate(
      {
        id,
        created_at: new Date(data.created_at).toISOString(),
        ...(contextImageFile("profile-photo") && {
          profile_photo: contextImageFile("profile-photo"),
        }),
        ...(contextImageFile("company-logo") && {
          company_logo: contextImageFile("company-logo"),
        }),
        ...rest,
      },
      {
        onSuccess: () =>
          showNotification({
            title: "Success",
            message: "Successfully updated",
          }),
        onError: (error: any) =>
          showNotification({
            title: "Error",
            message: error.response.data.message,
          }),
      }
    );
  };

  useMemo(() => {
    const defaultValuesObject = {
      honorific: data?.honorific ?? "",
      name: data?.name ?? "",
      middle_name: data?.middle_name ?? "",
      job_title: data?.job_title ?? "",
      country: data?.country ?? "",
      city: data?.city ?? "",
      email: data?.email ?? "",
      phone: data?.phone ?? "",
      company_name: data?.company_name ?? "",
      company_website: data?.company_website ?? "",
      company_description: data?.company_description ?? "",
      content_of_presentations: data?.content_of_presentations ?? "",
      panel_name: data?.panel_name ?? "",
      created_at: data?.created_at ?? "",
      speech_type: data?.speech_type ?? "",
      timezone: data?.timezone ?? "",
      publications: data?.publications ?? "",
      speaker_bio: data?.speaker_bio ?? "",
    };
    Object.keys(defaultValuesObject).forEach((value: any) => {
      setValue(value, data?.[value]);
    });
    Object.keys(data?.social_media_links ?? []).forEach((value: any) => {
      setValue(
        `social_media_links.${value}.url`,
        data?.social_media_links?.[value]?.url ?? ""
      );
      setValue(
        `social_media_links.${value}.followers_count`,
        parseInt(data?.social_media_links?.[value]?.followers_count) ?? ""
      );
    });

    setApproved(data?.is_approved);
  }, [setValue, data]);

  useMemo(() => {
    if (Object.keys(errors).length)
      showNotification({
        color: "red",
        icon: <IconX />,
        title: "Error",
        message: "Please fill in the required fields",
      });
  }, [errors]);

  const handleApproveSpeaker = () => (
    <Button
      leftIcon={<IconCheck />}
      color="teal.6"
      radius="xl"
      fw={400}
      onClick={() =>
        approveSpeaker(id, {
          onSuccess: () => {
            setApproved(true);
            showNotification({
              title: "Success",
              message: "Successfully updated",
            });
          },
        })
      }
      disabled={approved}
      loading={loadingApprove}
    >
      {approved ? "Approved" : "Approve speaker"}
    </Button>
  );

  return (
    <PageWrapper
      title="Speaker details"
      navigateBack
      action={handleApproveSpeaker()}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading && !isFetched ? (
          Array.from(Array(value.length).keys()).map((key) => (
            <Skeleton key={key} height={150} radius="sm" my={20} />
          ))
        ) : (
          <FormProvider {...methods}>
            <Accordion
              radius="md"
              variant="separated"
              multiple
              value={value}
              onChange={setAccordion}
            >
              <Accordion.Item value="item-1">
                <Accordion.Control>
                  <Text fw={500}>Speaker personal details</Text>
                </Accordion.Control>
                <Accordion.Panel p="md">
                  <SpeakerPersonalDetailsForm
                    currentPhoto={data?.profile_photo?.path}
                  />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item value="item-2">
                <Accordion.Control>
                  <Text fw={500}>Company Details</Text>
                </Accordion.Control>
                <Accordion.Panel p="md">
                  <CompanyDetailsForm currentPhoto={data?.company_logo?.path} />
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="item-3">
                <Accordion.Control>
                  <Text fw={500}>Presentation Details</Text>
                </Accordion.Control>
                <Accordion.Panel p="md">
                  <PresentationDetailsForm />
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="item-4">
                <Accordion.Control>
                  <Text fw={500}>Bio & Publications</Text>
                </Accordion.Control>
                <Accordion.Panel p="md">
                  <SpeakerBioAndPublications />
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="item-5">
                <Accordion.Control>
                  <Text fw={500}>Previous speaking experience</Text>
                </Accordion.Control>
                <Accordion.Panel p="md">
                  <PreviousSpeakingExperiences
                    data={data?.previous_speaking_experience}
                  />
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="item-6">
                <Accordion.Control>
                  <Text fw={500}>Social Media Links</Text>
                </Accordion.Control>
                <Accordion.Panel p="md">
                  <SocialMediaLinks />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
            <Button mt={20} type="submit">
              Update Speaker
            </Button>{" "}
          </FormProvider>
        )}
      </form>
    </PageWrapper>
  );
};

export default Speaker;
