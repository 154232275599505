import { Button, Flex, Select, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconPlus, IconX } from "@tabler/icons";
import CoreModal from "components/core/CoreModal";
import CoreTable from "components/core/CoreTable";
import PageWrapper from "components/core/PageWrapper";
import TicketForm from "components/forms/tickets/TicketForm";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
	useAllTickets,
	useCreateTicket,
	useDeleteTicket,
} from "services/enteties/tickets";
import { TicketData } from "types/ticket";

const Tickets = () => {
	const navigate = useNavigate();
	const [showByType, setShowbyType] = useState<string>("all");
	const [opened, setOpened] = useState(false);
	const [deleteId, setDeleteId] = useState<any>("");

	const methods = useForm<TicketData>();
	const { handleSubmit, reset } = methods;

	useEffect(() => {
		reset();
	}, [reset]);

	const { data: tickets, isLoading, isFetching } = useAllTickets();
	const { mutate: createTicket } = useCreateTicket();
	const { mutate: deleteTicket } = useDeleteTicket();

	//Filter by Type
	const filteredTickets =
		showByType === "all"
			? tickets
			: tickets?.filter((t: any) => t.type === showByType);

	// Sort by date
	const sortedTickets =
		filteredTickets &&
		filteredTickets.sort((a, b) => {
			if (a.start_date === null) {
				return -1; // Move ticket with no start date to top
			}
			if (b.start_date === null) {
				return 1; // Move ticket with no start date to top
			}
			// Sort by start date
			const dateA = new Date(a.start_date);
			const dateB = new Date(b.start_date);
			return dateA.getTime() - dateB.getTime();
		});

	const onSubmit = (data: TicketData) => {
		createTicket(
			{
				...data,
				price: data.price * 100,
			},
			{
				onSuccess: () => {
					showNotification({
						title: "Success",
						message: "Ticket created successfully",
						icon: <IconCheck />,
					});
					setOpened(false);
					reset();
				},
				onError: (error: any) => {
					showNotification({
						title: "Error",
						message: error.response.data.message,
						icon: <IconX />,
						color: "red",
					});
				},
			}
		);
	};

	const headerColumns = [
		{ id: 1, title: "id" },
		{ id: 2, title: "Name" },
		{ id: 3, title: "Price" },
		{ id: 4, title: "Type" },
		{ id: 5, title: "Start date" },
		{ id: 6, title: "End date" },
		{ id: 7, title: "Available" },
		{ id: 8, title: "Created" },
		{ id: 9, title: "Updated" },
		{ id: 10, title: "Actions" },
	];

	// Ticket price is ticket.price/100
	const rows = sortedTickets?.map((ticket: any, key: number) => (
		<tr key={`ticket-${key}`}>
			<td>{ticket.id}</td>
			<td>{ticket.name}</td>
			<td>{ticket.price / 100 || "free"}</td>
			<td>{ticket.type}</td>
			<td>
				{ticket.start_date
					? new Date(ticket.start_date).toLocaleDateString()
					: ""}
			</td>
			<td>
				{ticket.end_date
					? new Date(ticket.end_date).toLocaleDateString()
					: ""}
			</td>
			<td>
				{ticket.is_available ? (
					<IconCheck size={17} />
				) : (
					<IconX size={17} />
				)}
			</td>
			<td>{new Date(ticket.created_at).toLocaleDateString()}</td>
			<td>{new Date(ticket.updated_at).toLocaleDateString()}</td>
			<td>
				<Button
					onClick={() => navigate(`/tickets/${ticket.id}`)}
					variant="subtle"
					size="xs"
				>
					Edit
				</Button>
				<Button
					onClick={() => setDeleteId(ticket.id)}
					variant="subtle"
					color="red"
					size="xs"
				>
					Delete
				</Button>
			</td>
		</tr>
	));

	return (
		<PageWrapper
			title="Tickets"
			action={
				<Button
					leftIcon={<IconPlus size={20} />}
					onClick={() => setOpened(true)}
				>
					Add Ticket
				</Button>
			}
		>
			{/* Modal - Delete */}
			<CoreModal
				opened={!!deleteId}
				onClose={() => setDeleteId("")}
				title={"Delete Ticket"}
			>
				<Text ta="center" fw={500} my={20}>
					Are you sure you want to delete this ticket?
				</Text>
				<Flex justify="space-evenly">
					<Button onClick={() => setDeleteId("")}>Cancel</Button>
					<Button
						onClick={() => {
							deleteTicket(deleteId);
							setDeleteId("");
						}}
					>
						Confirm
					</Button>
				</Flex>
			</CoreModal>

			{/* Modal - Add new ticket */}
			<CoreModal
				opened={opened}
				onClose={() => setOpened(false)}
				title={"Create New Ticket"}
				size={850}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormProvider {...methods}>
						<TicketForm />
					</FormProvider>
					<Button mx="auto" type="submit" mt={20}>
						Create Ticket
					</Button>
				</form>
			</CoreModal>

			<Flex>
				<Select
					label="Ticket type"
					placeholder="Pick one"
					value={showByType}
					data={[
						{ value: "all", label: "All" },
						{ value: "affiliate", label: "Affiliate" },
						{ value: "normal", label: "Normal" },
					]}
					onChange={(value) => {
						if (value) setShowbyType(value);
					}}
				/>
			</Flex>

			{/* Table */}
			<CoreTable
				rows={rows}
				headerColumns={headerColumns}
				loading={isLoading}
				fetching={isFetching}
			/>
		</PageWrapper>
	);
};

export default Tickets;
