import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Button,
  Select,
  Loader,
  Tabs,
} from "@mantine/core";
import { useGetSponsorshipsByEventContextMenu } from "services/enteties/sponsorships/sponsorship-package";
import { useUpdateSponsorship } from "services/enteties/sponsorships/sponsorship-package/useUpdateSponsorship";
import { Controller } from "react-hook-form";
import { useGetSponsorshipById } from "services/enteties/sponsorships";
import { useExportSponsorships } from "services/enteties/sponsorships/sponsorship-package";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import SponsorshipDetailsForm from "./SponsorshipDetailsForm";
import { showNotification } from "@mantine/notifications";
import { useDeleteSponsorship } from "services/enteties/sponsorships/sponsorship-package/useDeleteSponsorship";
import SponsorshipFeatureHandler from "./SponsorshipFeatureHandler";
import CreateSponsorship from "./CreateSponsorship";
import { CSVDownload } from "react-csv";
import UpdateFeature from "./UpdateFeature";

import { IconTrash, IconFileExport } from "@tabler/icons";

const UpdateSponsorship = ({id}: {id: string | undefined}) => {
  const { data, isLoading: isLoadingSponsorshipList } = useGetSponsorshipsByEventContextMenu(id);
  const [sponsorshipId, setSponsorshipId] = useState("");

  const { mutate: updateSponsorship } = useUpdateSponsorship();
  const { mutate: deleteSponsorship } = useDeleteSponsorship();
  const { data: dataExport } = useExportSponsorships();
  const [file, setFile] = useState<string[][]>([]);
  const [tabs, setTabs] = useState<any>("1");

  const methods = useForm({
    resolver: yupResolver(yup.object({})),
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const { data: sponsorData, isLoading: isLoadingSponsorData } =
    useGetSponsorshipById(sponsorshipId);
  const [loadingSponsorship, setLoadingSponsorship] = useState(true);

  useEffect(()=>{
    setLoadingSponsorship(isLoadingSponsorData && isLoadingSponsorshipList)
  },[isLoadingSponsorData, isLoadingSponsorshipList])

  useMemo(() => {
    setSponsorshipId(data?.[0]?.value.toString());
  }, [data, setSponsorshipId]);

  useMemo(() => {
    const defaultValuesObject = {
      name: sponsorData?.name ?? "",
      description: sponsorData?.description ?? "",
      price: sponsorData?.price ?? "",
      color_scheme: sponsorData?.color_scheme ?? null,
      amount_reserved: sponsorData?.amount_reserved ?? "",
      amount_sold: sponsorData?.amount_sold ?? "",
      quantity: sponsorData?.quantity ?? "",
      currency: sponsorData?.currency ?? "",
      is_main: sponsorData?.is_main ? true : false,
      is_published: sponsorData?.is_published ? true : false,
    };

    Object.keys(defaultValuesObject).forEach((value: any) => {
      setValue(value, sponsorData?.[value]);
    });

    setValue('price', sponsorData?.price/100);
  }, [setValue, sponsorData]);

  const onChangeSponsorship = (data: any) => setSponsorshipId(data);

  const onSubmit = (data: any) => {
    updateSponsorship(
      {
        id: sponsorshipId,
        data: {
          ...data,
          price: data.price*100,
          is_main: data.is_main ? 1 : 0,
          is_published: data.is_published ? 1 : 0,
        },
      },
      {
        onSuccess: () => {
          showNotification({
            title: "Success",
            message: "Successfully updated",
          });
        },
      }
    );
  };
  const handleDeleteSponsorship = () =>
    deleteSponsorship(sponsorshipId, {
      onSuccess: () => {
        showNotification({
          title: "Success",
          message: "Successfully deleted",
        });
        setSponsorshipId(data?.[0].value.toString());
      },
    });

  const generateCSV = () => {
    if (dataExport) {
      const subArrays: any = dataExport
        .trim()
        .split("\n")
        .map((str: string) =>
          str
            .split(",")
            .map((field: string) =>
              field.replace(/"/g, "").replace(/,,/g, ",").trim()
            )
        )
        .slice(1)
        .reduce(
          (acc: string[][], curr: string[any]) => (
            acc[acc.length - 1].length === 11
              ? acc.push([curr])
              : acc[acc.length - 1].push(curr),
            acc
          ),
          [[]]
        );
      setFile(subArrays[0]);
    }
  };
  return (
    <Box mih={700}>
      <Flex align="center" justify="space-between">
        {data && tabs <= 2 && (
          <>
            <Controller
              name="sponsorships"
              control={control}
              render={({ field }) => (
                <Select
                  icon={isLoadingSponsorshipList && <Loader size="sm" />}
                  {...field}
                  withAsterisk
                  onChange={onChangeSponsorship}
                  defaultValue={sponsorshipId}
                  data={data ?? []}
                  maxDropdownHeight={280}
                  miw={"50%"}
                  error={errors?.sponsorships?.message as string}
                />
              )}
            />
            <Flex justify="space-between" align="center">
              <Button
                variant="light"
                color="red"
                size="xs"
                rightIcon={<IconTrash size="1.2rem" />}
                mx={10}
                disabled={loadingSponsorship}
                onClick={handleDeleteSponsorship}
              >
                Delete
              </Button>
              <Button
                variant="light"
                color="blue"
                size="xs"
                rightIcon={<IconFileExport size="1.2rem" />}
                ml={10}
                onClick={() => generateCSV()}
              >
                Export all to CSV
              </Button>
            </Flex>
          </>
        )}

        {file?.length ? <CSVDownload data={file} target="_blank" /> : ""}
      </Flex>
      <Tabs
        color="gray"
        variant="pills"
        radius="xl"
        value={tabs}
        onTabChange={setTabs}
      >
        <Tabs.List mt={20}>
          <Tabs.Tab value="1">Edit Sponsorship</Tabs.Tab>
          <Tabs.Tab value="2">Sponsorship Features</Tabs.Tab>
          <Tabs.Tab value="3">Create Sponsorship</Tabs.Tab>
          <Tabs.Tab value="4">Handle Sponsorship Features</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="1" pt="xs">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <SponsorshipDetailsForm loading={loadingSponsorship} />
            </FormProvider>
            <Button type="submit">Update Sponsorship</Button>
          </form>
        </Tabs.Panel>
        <Tabs.Panel value="2" pt="xs">
          <SponsorshipFeatureHandler
            data={sponsorData}
            loading={loadingSponsorship}
            sponsorshipId={sponsorshipId}
          />
        </Tabs.Panel>
        <Tabs.Panel value="3" pt="xs">
          <CreateSponsorship id={id} />
        </Tabs.Panel>
        <Tabs.Panel value="4" pt="xs">
          <UpdateFeature />
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

export default UpdateSponsorship;
