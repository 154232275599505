import http from "services/http";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteTicket = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (userId: any) => http.delete(`/api/tickets/${userId}`),
		onSuccess: () => {
			queryClient.invalidateQueries(["tickets"]);
		},
	});
};
