import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetSponsorshipMain = (id: string | undefined) => {
	return useQuery(
		["sponsorships-main"],
		() => {
			if (id === undefined) return Promise.resolve(null);
			return http.get(
				`/api/sponsorships?filter[event_id]=${id}&filter[main]=true&include=features`
			);
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			select(data) {
				return data?.data.data ?? null;
			},
		}
	);
};
