import http from "services/http";
import { useQuery } from "@tanstack/react-query";
export const useGetHotelsId = (hotelIds: string | undefined) => {
  return useQuery(
    ["hotel", hotelIds],
    () => http.get(`/api/hotels/?filter[id]=${hotelIds}`),
    {
      enabled: !!hotelIds,
      select(data) {
        return data.data;
      },
    }
  );
};
